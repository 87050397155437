<template>
  <v-text-field v-show="visible" v-model="value" :label="label" :readonly="isReadonly" :rules="rules" />
</template>

<script>
import getModuleName from '@/components/modelForm/utils/getModuleName';
import { required } from '@/utils/rules';

export default {
  name: 'MFInput',
  props: {
    modelId: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
  },
  computed: {
    moduleName() {
      return getModuleName(this.modelId);
    },
    moduleState() {
      return this.$store.state[this.moduleName];
    },
    storedModel() {
      return this.moduleState.model || {};
    },
    value: {
      get() {
        return this.moduleState.values[this.fieldName];
      },
      set(newVal) {
        this.$store.commit(`${this.moduleName}/setModelField`, { name: this.fieldName, value: newVal });
        this.fieldChanged();
      },
    },
    label() {
      return this.storedModel[this.fieldName].label;
    },
    visible() {
      return this.$store.getters[`${this.moduleName}/isFieldVisible`](this.fieldName);
    },
    isRequired() {
      return this.$store.getters[`${this.moduleName}/isFieldRequired`](this.fieldName);
    },
    isReadonly() {
      return this.$store.getters[`${this.moduleName}/isFieldReadonly`](this.fieldName);
    },
    rules() {
      return this.isRequired ? [required('Обязательное поле')] : [];
    },
  },
  methods: {
    fieldChanged() {
      this.$emit('field-changed', this.value);
    },
  },
};
</script>

<style scoped lang="scss"></style>
