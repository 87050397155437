import { NUMBER_OPERATOR_VALUES, STRING_OPERATOR_VALUES, TYPES } from '../_const/filterConstants';

const _isType = (typeOrFilter, sampleType) => {
  return typeOrFilter?.type === sampleType || typeOrFilter === sampleType;
};

const isEnum = (typeOrFilter) => {
  return _isType(typeOrFilter, TYPES.ENUM);
};

const isMultiple = (typeOrFilter) => {
  return _isType(typeOrFilter, TYPES.MULTIPLE);
};

const isString = (typeOrFilter) => {
  return _isType(typeOrFilter, TYPES.STRING);
};

const isNumber = (typeOrFilter) => {
  return _isType(typeOrFilter, TYPES.NUMBER);
};

const isDate = (typeOrFilter) => {
  return _isType(typeOrFilter, TYPES.DATE);
};

const isSelect = (typeOrFilter) => {
  return isEnum(typeOrFilter) || isMultiple(typeOrFilter);
};

const isTextField = (typeOrFilter) => {
  return isNumber(typeOrFilter) || isString(typeOrFilter);
};

const stringFilterNeedsValue = (operator) => {
  return (
    operator !== STRING_OPERATOR_VALUES.IS_EMPTY && operator !== STRING_OPERATOR_VALUES.IS_NOT_EMPTY && operator !== STRING_OPERATOR_VALUES.IS_NULL && operator !== STRING_OPERATOR_VALUES.IS_NOT_NULL
  );
};

const rangeAvailable = (operator) => {
  return !!operator && operator !== NUMBER_OPERATOR_VALUES.EQUALS && operator !== NUMBER_OPERATOR_VALUES.NOT_EQUALS;
};

const rangeOpen = (range, operator) => {
  return range?.active && rangeAvailable(operator);
};

export { isEnum, isMultiple, isString, isNumber, isDate, isSelect, isTextField, stringFilterNeedsValue, rangeAvailable, rangeOpen };
