<template>
  <v-dialog width="30%" :value="visible" @input="visible = $event">
    <v-card>
      <v-card-title>
        Обновить
        <v-spacer />
        <v-btn text @click="visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions class="pb-6 justify-space-around">
        <v-btn class="text" @click="refresh(true)"> Обновить с пересборкой </v-btn>
        <v-btn class="primary" @click="refresh(false)"> Обновить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { SET_NEEDS_REFRESH, SET_REFRESH_FLOW_DIALOG } from '@/store/mutations';
import { GET_MAP } from '@/store/actions';

export default {
  name: 'RefreshFlowDialog',
  computed: {
    ...mapState('flows', ['refreshFlowDialog']),
    ...mapGetters('flows', ['moduleName']),
    visible: {
      get() {
        return this.refreshFlowDialog;
      },
      set(newVal) {
        this[SET_REFRESH_FLOW_DIALOG](newVal);
      },
    },
  },
  methods: {
    ...mapMutations('flows', [SET_REFRESH_FLOW_DIALOG]),
    async refresh(rebuild = false) {
      await this.$store.dispatch(`${this.moduleName}/${GET_MAP}`, rebuild);
      await this.$store.commit(`${this.moduleName}/${SET_NEEDS_REFRESH}`, true);
      this.visible = false;
    },
  },
};
</script>

<style scoped></style>
