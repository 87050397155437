<template>
  <div v-show="loading" class="layout">
    <div class="lds-dual-ring"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('common', ['loading']),
  },
};
</script>
<style lang="scss" scoped>
.layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  position: absolute;
  z-index: 10001;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
