<template>
  <v-main class="main-map pa-0">
    <div :id="rootId" :ref="rootId" class="drawflow" @drop.prevent="dropEnd($event)" @dragover.prevent="setDragEffect($event)">
      <TopControls class="main-map__top-controls" />
      <!-- <FlowTabs />-->
      <!-- FIXME: Change class name (https://jerosoler.github.io/drawflow-theme-generator/) -->
      <!-- FIXME: Bad position when dropping on existing node -->
      <MapControls class="main-map__controls" @lock-toggle="toggleLock" @zoom-in="zoomIn" @zoom-out="zoomOut" @zoom-reset="zoomReset" />
    </div>
  </v-main>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import styleDrawflow from 'drawflow/dist/drawflow.min.css';
// eslint-disable-next-line no-unused-vars
import overrideStyleDrawflow from '../styles/drawflow-override.min.css';

import DrawFlow from 'drawflow';
import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import { mapGetters, mapMutations, mapState } from 'vuex';
// import FlowTabs from '@/components/flows/FlowTabs';
import {
  SET_FLOW_MOUNTED,
  SET_SIDE_PANEL,
  SET_DRAWFLOW_WIDTH,
  TOGGLE_LOCK,
  SET_ZOOM,
  SET_CANVAS_POSITION,
  REGISTER_NODE,
  REGISTER_EDGE,
  SET_SIDE_PANEL_TYPE,
  SET_SIDE_PANEL_DATA_TYPE,
  SET_ACTIVE_EDGE_ID,
  SET_NODE_IDS,
  SET_EDGE_IDS,
  SET_NEED_TO_REDRAW,
  SET_NODE_NEW_COORDS,
  SET_NEEDS_REFRESH,
  SET_ACTIVE_FLOW_ID,
} from '@/store/mutations';
import { GET_MAP, INIT_EDGE } from '@/store/actions';
import FlowNode from '@/components/flows/FlowNode';
import MapControls from '@/components/flows/MapControls';
import TopControls from '@/components/flows/TopControls';
import Flow from '@/store/modules/flow';

export default {
  name: 'MainMap',
  components: {
    // FlowTabs,
    MapControls,
    TopControls,
  },
  data() {
    return {
      editor: null,
      rootId: 'drawflow',
    };
  },
  computed: {
    ...mapState('flows', ['activeFlowId', 'needsToBeRedrawn']),
    ...mapState('common', ['temporaryErrors']),
    ...mapGetters('flows', ['moduleName', 'edgeModuleName']),
    lastNodeMove() {
      if (this.moduleName) return this.$store.state[this.moduleName].lastNodeMove;
      return null;
    },
    needsRefresh() {
      if (this.moduleName) return this.$store.state[this.moduleName].needsRefresh;
      return null;
    },
  },
  watch: {
    '$route.params.flowId': {
      async handler(newVal) {
        if (!newVal) return;
        await this.registerOrChangeModule(newVal);
        this.setTabName({
          fullPath: this.$route.fullPath,
          newName: `Карта - ${this.$store.state[this.moduleName]?.rootNodeName || 'Безымянная'}`,
        });
      },
      immediate: true,
    },
    needsToBeRedrawn(newVal) {
      if (newVal === true) {
        this.changeAllLinesStyle();
        this[SET_NEED_TO_REDRAW](false);
      }
    },
    needsRefresh(newVal) {
      if (newVal) this.refreshModule();
    },
    lastNodeMove(newVal) {
      const canvasOffsets = this.$refs[this.rootId].getBoundingClientRect();
      const x = newVal.x - canvasOffsets.x;
      const y = newVal.y - canvasOffsets.y;
      const [X, Y] = this.calculateDropCoords(x, y);
      if (newVal) {
        this.$store.commit(`${this.moduleName}/node_${newVal.id}/${SET_NODE_NEW_COORDS}`, { x: X, y: Y });
      }
    },
    temporaryErrors(newArr) {
      const errRoutes = newArr.filter(({ type }) => type === 'route').map(({ elementid }) => elementid);
      this.handleRouteErrors(errRoutes);
    },
  },
  mounted() {
    this.initDrawFlow();
    this.getDrawflowWidth();
    window.addEventListener('resize', this.getDrawflowWidth);
    const firstFlowId = this.$route.params.flowId;
    this.registerOrChangeModule(firstFlowId);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getDrawflowWidth);
  },
  methods: {
    ...mapMutations('flows', [SET_SIDE_PANEL, SET_DRAWFLOW_WIDTH, SET_ACTIVE_EDGE_ID, SET_ACTIVE_FLOW_ID, SET_SIDE_PANEL_TYPE, SET_SIDE_PANEL_DATA_TYPE, SET_NEED_TO_REDRAW]),
    ...mapMutations('tabs', ['setTabName']),
    addEdgeEventListener({ input_id, output_id }) {
      let edgeId = this.$store.state[this.moduleName].edgeIds.find((edgeId) => {
        const edgeData = this.$store.state[`${this.moduleName}/edge_${edgeId}`];
        return edgeData.source_node === output_id && edgeData.target_node === input_id;
      });
      let edgeData = this.$store.state[`${this.moduleName}/edge_${edgeId}`] || undefined;
      if (!edgeId) {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          edgeId = uuid().toUpperCase();
          const index = this.$store.state[this.moduleName].edgeIds.findIndex((_edgeId) => _edgeId === edgeId);
          if (index === -1) break;
        }
        edgeData = {
          id: edgeId,
          label: 'Новый роут',
          source_node: output_id,
          target_node: input_id,
          type: 'route',
          oid: null,
          data: { name: 'Новый роут', type: 'default' },
        };
        this.$store.commit(`${this.moduleName}/${REGISTER_EDGE}`, edgeData);
      }
      // if (edgeData.type === 'different') this.changeLineStyle(input_id, output_id);
      const connection = this.findEdge(input_id, output_id);
      connection?.addEventListener('dblclick', () => this.onEdgeDblClick(edgeId));
    },

    async onEdgeDblClick(id) {
      // if (!this.$store.state[this.moduleName].locked) {
      this[SET_ACTIVE_EDGE_ID](id);
      await this.$store.dispatch(`${this.edgeModuleName}/${INIT_EDGE}`);
      this[SET_SIDE_PANEL_TYPE]('data');
      this[SET_SIDE_PANEL_DATA_TYPE]('edge');
      this[SET_SIDE_PANEL](true);
      // }
    },

    initDrawFlow() {
      this.editor = new DrawFlow(this.$refs[this.rootId], Vue, this);
      this.editor.curvature = 0;
      this.editor.on('connectionCreated', (data) => this.addEdgeEventListener(data));
      this.editor.on('translate', () => this.saveCanvasData());
      this.editor.on('zoom', () => this.saveCanvasData());
      this.editor.on('nodeRemoved', (id) => this.deleteNodeModule(id));
      this.editor.on('connectionRemoved', (data) => this.deleteEdgeModule(data));
      this.editor.on('nodeMoved', (data) => console.log(data));

      this.editor.start();
    },
    async registerOrChangeModule(id) {
      if (id) {
        const moduleName = `flows/flow_${id}`;
        if (!this.$store.hasModule(moduleName)) {
          this.$store.registerModule(moduleName, new Flow(id));
        }
        if (!this.$store.getters[`${moduleName}/mapLoaded`]) {
          await this.$store.dispatch(`${moduleName}/${GET_MAP}`);
        }
        if (this.editor) {
          this[SET_ACTIVE_FLOW_ID](id);
          if (!this.flowDrawn(id)) this.initModule(id);
          else this.changeModule(id);
        }
      }
    },
    flowDrawn(id) {
      return id && !!this.$store.state[this.moduleName]?.mounted;
    },
    initModule(id) {
      this.editor.addModule(id);
      this.$store.commit(`${this.moduleName}/${SET_FLOW_MOUNTED}`);
      this.changeModule(id);
      this.drawModule();
    },
    drawModule() {
      this.$store.state[this.moduleName].nodeIds.forEach((nodeId) => {
        const nodeData = this.$store.state[`${this.moduleName}/node_${nodeId}`];
        this.addNode(nodeData.id, nodeData.name, nodeData.description, nodeData.X, nodeData.Y, nodeData.type);
      });
      this.$store.state[this.moduleName].edgeIds.forEach((edgeId) => {
        const edgeData = this.$store.state[`${this.moduleName}/edge_${edgeId}`];
        const source = edgeData.source_node;
        const target = edgeData.target_node;
        this.editor.addConnection(source, target, 'output_1', 'input_1');
      });
      this.changeAllLinesStyle();
      this.addAllEdgesEventListeners();
    },
    refreshModule() {
      this.editor.clearModuleSelected();
      this.drawModule();
      this.cancelNeedToRefresh();
    },
    changeModule(id) {
      if (id) {
        this.editor.changeModule(id);
        this.checkLock();
        this.setCanvas(this.$store.state[this.moduleName].canvasPosition.x, this.$store.state[this.moduleName].canvasPosition.y, this.$store.state[this.moduleName].zoom);
      }
      this.$nextTick(() => {
        this.addAllEdgesEventListeners();
        this.changeAllLinesStyle();
      });
    },
    checkLock() {
      this.editor.editor_mode = this.$store.state[this.moduleName]?.locked ? 'view' : 'edit';
    },
    toggleLock() {
      this.$store.commit(`${this.moduleName}/${TOGGLE_LOCK}`);
      this.checkLock();
    },
    zoomIn() {
      this.editor.zoom_in();
      this.saveZoom();
    },
    zoomOut() {
      this.editor.zoom_out();
      this.saveZoom();
    },
    zoomReset() {
      this.editor.canvas_x = 0;
      this.editor.canvas_y = 0;
      this.setCanvas(0, 0, 1);
      this.saveZoom();
      this.saveCanvasPosition();
    },
    saveZoom() {
      this.$store.commit(`${this.moduleName}/${SET_ZOOM}`, this.editor.zoom);
    },
    saveCanvasPosition() {
      this.$store.commit(`${this.moduleName}/${SET_CANVAS_POSITION}`, {
        x: this.editor.canvas_x,
        y: this.editor.canvas_y,
      });
    },
    saveCanvasData() {
      // FIXME: Saving previous state
      this.saveZoom();
      this.saveCanvasPosition();
    },
    setCanvas(x, y, zoom) {
      this.editor.zoom_last_value = zoom;
      this.editor.zoom = zoom;
      const drawflow = this.$refs[this.rootId].getElementsByClassName('drawflow')[0];
      drawflow.style.transform = `translate(${x}px, ${y}px) scale(${zoom})`;
    },
    addNode(id, name, description, x, y, type = 'step') {
      const inputs = type === 'root' ? 0 : 1;
      const lastNodeId = this.editor.nodeId;
      this.editor.nodeId = id;
      this.editor.registerNode(id, FlowNode, { id, name, description, root: type === 'root', step: type === 'step' });
      this.editor.addNode(id, inputs, 1, x, y, type, `null`, id, 'vue');
      this.editor.nodeId = lastNodeId;
    },
    addAllEdgesEventListeners() {
      this.$store.state[this.moduleName].edgeIds.forEach((edgeId) => {
        const edge = this.$store.state[`${this.moduleName}/edge_${edgeId}`];
        this.addEdgeEventListener({ input_id: edge.target_node, output_id: edge.source_node });
      });
    },
    changeAllLinesStyle() {
      this.$store.state[this.moduleName].edgeIds.forEach((edgeId) => {
        const fullModule = `${this.moduleName}/edge_${edgeId}`;
        const edgeData = this.$store.getters[`modelForm_${fullModule}/route_model/data`];
        const edge = this.$store.state[fullModule];
        if (edgeData?.type === 'different' || edge?.data?.type === 'different') {
          this.changeLineStyle(edge.target_node, edge.source_node);
        } else {
          this.changeLineStyle(edge.target_node, edge.source_node, false);
        }
      });
    },
    handleRouteErrors(routeIdsArr) {
      if (routeIdsArr.length) {
        this.$store.state[this.moduleName]?.edgeIds.forEach((edgeId) => {
          const edge = this.$store.state[`${this.moduleName}/edge_${edgeId}`];
          if (routeIdsArr.includes(edgeId)) {
            this.changeLineStyle(edge.target_node, edge.source_node, true, 'entity-error');
          } else {
            this.changeLineStyle(edge.target_node, edge.source_node, false, 'entity-error');
          }
        });
      }
    },
    changeLineStyle(inputId, outputId, different = true, className = 'different') {
      const connection = this.findEdge(inputId, outputId);
      if (connection && connection.classList) {
        if (different) {
          connection.classList.add(className);
        } else {
          connection.classList.remove(className);
        }
      }
    },
    generateQuery(inputId, outputId) {
      return `.connection.node_in_node-${inputId}.node_out_node-${outputId}.input_1.output_1`;
    },
    findEdge(inputId, outputId) {
      const query = this.generateQuery(inputId, outputId);
      return document.querySelector(query);
    },
    getDrawflowWidth() {
      this[SET_DRAWFLOW_WIDTH](this.$refs[this.rootId].clientWidth);
    },
    dropEnd(e) {
      const data = JSON.parse(e.dataTransfer.getData('text/plain'));
      this.createNewNode(data.type, data.description, ...this.calculateDropCoords(e.layerX, e.layerY), data.has_task);
    },
    setDragEffect(e) {
      e.dataTransfer.dropEffect = 'copy';
    },
    calculateDropCoords(layerX, layerY) {
      const canvas = this.$refs[this.rootId];
      const center = { x: canvas.clientWidth / 2, y: canvas.clientHeight / 2 };
      return [(layerX - center.x) / this.editor.zoom + center.x - this.editor.canvas_x / this.editor.zoom, (layerY - center.y) / this.editor.zoom + center.y - this.editor.canvas_y / this.editor.zoom];
    },
    createNewNode(name, description, x, y, has_task, type = 'step') {
      let newNodeId;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        newNodeId = uuid().toUpperCase();
        const index = this.$store.state[this.moduleName].nodeIds.findIndex((nodeId) => nodeId === newNodeId);
        if (index === -1) break;
      }
      this.$store.commit(`${this.moduleName}/${REGISTER_NODE}`, {
        id: newNodeId,
        data: { name: 'Новая нода', description, type: name },
        X: x,
        Y: y,
        oid: null,
        taskid: null,
        task_data: null,
        type: type,
        is_task_exe: false,
        has_task: has_task,
      });
      this.addNode(newNodeId, name, description, x, y);
    },
    deleteNodeModule(id) {
      const nodeModuleName = `${this.moduleName}/node_${id}`;
      if (this.$store.hasModule(nodeModuleName)) {
        this.$store.unregisterModule(nodeModuleName);
      }
      const nodeIds = [...this.$store.state[this.moduleName].nodeIds];
      const index = nodeIds.indexOf(id);
      if (index !== -1) {
        nodeIds.splice(index, 1);
        this.$store.commit(`${this.moduleName}/${SET_NODE_IDS}`, nodeIds);
      }
    },
    deleteEdgeModule({ input_id: inputId, output_id: outputId }) {
      const edgeIds = [...this.$store.state[this.moduleName].edgeIds];
      const id = edgeIds.find((edgeId) => {
        const edge = this.$store.state[`${this.moduleName}/edge_${edgeId}`];
        return edge.source_node === outputId && edge.target_node === inputId;
      });
      const index = edgeIds.findIndex((edgeId) => edgeId === id);
      if (index !== -1) {
        edgeIds.splice(index, 1);
        this.$store.commit(`${this.moduleName}/${SET_EDGE_IDS}`, edgeIds);
      }
      const edgeModuleName = `${this.moduleName}/edge_${id}`;
      if (this.$store.hasModule(edgeModuleName)) {
        this.$store.unregisterModule(edgeModuleName);
      }
    },
    cancelNeedToRefresh() {
      if (this.moduleName) this.$store.commit(`${this.moduleName}/${SET_NEEDS_REFRESH}`, false);
    },
  },
};
</script>

<style scoped lang="scss">
.main-map {
  height: $content-height;
  position: relative;

  &__controls {
    position: absolute;
    bottom: 50px;
    right: 0;
    z-index: 5;
  }

  &__top-controls {
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 5;
  }

  .drawflow {
    height: 100%;
  }

  &::v-deep .drawflow {
    width: 100%;
    height: 100%;
  }

  &::v-deep .drawflow-node .inputs,
  &::v-deep .drawflow-node .outputs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
  }

  &::v-deep .drawflow-node .inputs {
    top: -10px;
  }

  &::v-deep .drawflow-node .outputs {
    bottom: -10px;
  }

  &::v-deep .drawflow .connection.different > path {
    stroke-dasharray: 15, 10;
  }

  &::v-deep .entity-error {
    path {
      stroke: red !important;
    }
  }
}
</style>
