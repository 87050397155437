import APP_SETTINGS from '@/appsettings';
import axios from 'axios';

const axiosConfig = {
  baseURL: APP_SETTINGS.BASE_API_URL,
  params: {
    tnx: null,
  },
  headers: {},
};

const server = axios.create(axiosConfig);

/**
 * @param {Function} resolve - Функция обработки запроса на сервер
 * @param {Function} reject - Функция обработки ошибки запроса на сервер
 */
export function addRequestInterceptor(resolve = (r) => r, reject = (e) => Promise.reject(e)) {
  server.interceptors.request.use(resolve, reject);
}

/**
 * @param {Function} resolve - Функция обработки запроса на сервер
 */
export function addRequestHandler(resolve) {
  addRequestInterceptor(resolve);
}

/**
 * @param {Function} reject - Функция обработки ошибки запроса на сервер
 */
export function addRequestErrorHandler(reject) {
  addRequestInterceptor((r) => r, reject);
}

/**
 * @param {Function} resolve - Функция обработки ответа сервера
 * @param {Function} reject - Функция обработки ошибки сервера
 */
export function addResponseInterceptor(resolve = (r) => r, reject = (e) => Promise.reject(e)) {
  server.interceptors.response.use(resolve, reject);
}

/**
 * @param {Function} reject - Функция обработки ошибки сервера
 */
export function addErrorHandler(reject) {
  addResponseInterceptor((r) => r, reject);
}

/**
 * @param {Function} resolve - Функция обработки ответа сервера
 */
export function addResponseHandler(resolve) {
  addResponseInterceptor(resolve);
}

export default server;
