import server from '@/api/server';

/**
 * // Получение самой карты для отображения на холсте
 * @param {string} id
 * @param {boolean} rebuild - флаг, для принудительного перестроения карты
 * @param {number} map_width - 2000, усл. ширина, используется для расчёта позиций блоков по гориз
 * @param {boolean} map_height - 2000, усл. высота карты, нужна для расчёта позиций блоков по вертик
 *
 * @typedef {Object} MapData
 * @property {Array} nodes
 * @property {Array} edges
 */
export async function getMap(data) {
  let res = await server.post('/map/get', data);
  return res;
}

// {
// 	"nodes": [{
// 		"id":"Идентификатор ноды",
// 		"dadd":"Дата создания",
// 		"parent_id":Идентификатор родительской ноды, использовать для привязки стрелок, вместо source_node, target_node
// 		"block_type":"", (не использовать, осталось для обратной совместимости)
// 		"style":"", (не использовать, осталось для обратной совместимости)
// 		"labelCfg":"", (не использовать, осталось для обратной совместимости)
// 		"label":"", (не использовать, осталось для обратной совместимости)
// 		"X":0, (расположение ноды на холсте по горизонтали)
// 		"Y":0, (расположение ноды на холсте по вертикали)
// 		"width":0, (ширина ноды, можно не использовать, а задать какой то базовый размер)
// 		"height":0, (высота ноды, можно не использовать, а задать какой то базовый размер)
// 		"type":"Тип шага", (технически это можно не использовать)
// 		"node_type":"Тип ноды", (два вида root или step, использовать для дизайна блоков)
// 		"oid":"Идентификатор шага привязанного к данной ноде",
// 		"data":{...}, (данные данного шага)
// 		"model":"", (используется для отображения дополнительных полей в зависимости от типов шагов)
// 		"taskid":"Идентификатор задачи привязанной к данному шагу",
// 		"task_data":{...} (данные задачи привязанной к данному шагу)
// 	}, {...}, ...],
// 	"edges": [{
// 		"id":"Идентификатор эджа",
// 		"dadd":"Дата создания",
// 		"line_type":"", (не использовать, осталось для обратной совместимости)
// 		"style":"", (не использовать, осталось для обратной совместимости)
// 		"labelCfg":"", (не использовать, осталось для обратной совместимости)
// 		"label":"", (не использовать, осталось для обратной совместимости)
// 		"source_node":"Идентификатор стартовой ноды",
// 		"target_node":"Идентификатор конечной ноды",
// 		"source_anchor":"", (не использовать, осталось для обратной совместимости)
// 		"target_anchor":"", (не использовать, осталось для обратной совместимости)
// 		"startX":"", (не использовать, осталось для обратной совместимости)
// 		"startY":"", (не использовать, осталось для обратной совместимости)
// 		"endX":"", (не использовать, осталось для обратной совместимости)
// 		"endY":"", (не использовать, осталось для обратной совместимости)
// 		"type":"Тип роута", (технически это можно не использовать)
// 		"edge_type":"Тип эджа", (default, different для отображения сплошной или пунктирной линии)
// 		"oid":"Идентификатор роута привязанного к данному эджу",
// 		"data":{...} (данные данного роута)
// 	}, {...}, ...]
// }
//
// Дисклеймер:
// Ноды != Шаги/Задачи
// Эджы != Роуты
// В данном контексте ноды это именно визуально отображаемые блоки, а эджи стрелки.
// При этом шаги, задачи и роуты  - это логические объекты,
// которые используются непосредственно базой данных и оперируют с ними
export async function saveMap(flowData) {
  let res = await server.post('/map/save', flowData);
  return res;
}

export async function publishFlow(data) {
  let res = await server.post('/publication/publish', data);
  return res;
}
