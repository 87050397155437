<template>
  <v-dialog v-model="dialogVisible" transition="dialog-bottom-transition" max-width="900">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark v-on="on">
        <v-icon>mdi-filter-outline</v-icon>
        Фильтры
      </v-btn>
    </template>
    <template>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Фильтры</v-toolbar-title>
        </v-toolbar>
        <modul-filter-form module-name="moduleName" :columns="filterColumns" @update:filters="updateFilters($event)" @reset:filters="resetFilters"></modul-filter-form>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import ModulFilterForm from './modul-filter-form';

export default {
  name: 'ModulFilter',
  components: {
    'modul-filter-form': ModulFilterForm,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    filterColumns() {
      return this.columns.filter((col) => !!col.allows_filter);
    },
  },
  methods: {
    updateFilters(e) {
      this.$emit('update:filters', e);
      this.dialogVisible = false;
    },
    resetFilters() {
      this.$emit('reset:filters');
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
