<template>
  <v-select v-if="!isComboBox" v-show="visible" v-model="value" :label="label" :items="options" item-text="label" item-value="value" :readonly="isReadonly" :rules="rules" @click="checkOptions" />
  <v-combobox v-else v-show="visible" v-model="value" :label="label" :items="options" item-text="label" item-value="value" :readonly="isReadonly" :rules="rules" @click="checkOptions" />
</template>

<script>
import getModuleName from '@/components/modelForm/utils/getModuleName';
import { required } from '@/utils/rules';

export default {
  name: 'MFSelect',
  props: {
    modelId: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    moduleName() {
      return getModuleName(this.modelId);
    },
    moduleState() {
      return this.$store.state[this.moduleName];
    },
    storedModel() {
      return this.moduleState.model || {};
    },
    value: {
      get() {
        return this.moduleState.values[this.fieldName];
      },
      set(val) {
        const oldValue = this.value;
        const parsedValue = this.parseValue(val);
        if (oldValue !== parsedValue) {
          this.$store.commit(`${this.moduleName}/setModelField`, { name: this.fieldName, value: parsedValue });
          this.fieldChanged();
        }
      },
    },
    label() {
      return this.storedModel[this.fieldName].label;
    },
    options() {
      return this.moduleState.lists[this.fieldName] || [];
    },
    visible() {
      return this.$store.getters[`${this.moduleName}/isFieldVisible`](this.fieldName);
    },
    isRequired() {
      return this.$store.getters[`${this.moduleName}/isFieldRequired`](this.fieldName);
    },
    isReadonly() {
      return this.$store.getters[`${this.moduleName}/isFieldReadonly`](this.fieldName);
    },
    isComboBox() {
      return !!this.storedModel[this.fieldName].data.selection?.allow_user_input;
    },
    rules() {
      return this.isRequired ? [required('Обязательное поле')] : [];
    },
  },
  methods: {
    parseValue(val) {
      if (this.isComboBox) {
        return val?.value ?? val;
      }
      return val;
    },
    async checkOptions() {
      if (!this.loading && this.visible && (!this.options || !this.options.length)) {
        this.loading = true;
        await this.$store.dispatch(`${this.moduleName}/updateModelList`, this.fieldName);
      }
      this.loading = false;
    },
    fieldChanged() {
      this.$emit('field-changed', this.value);
    },
  },
};
</script>

<style scoped lang="scss"></style>
