import { SET_GRID } from '@/store/mutations';
import { GET_GRID_DATA } from '@/store/actions';
import { getGridData } from '@/api';

const initState = () => ({
  gridData: [],
});

export const grid = {
  namespaced: true,
  state: initState(),
  mutations: {
    [SET_GRID]: (state, { data }) => {
      state.gridData = data;
    },
  },
  actions: {
    [GET_GRID_DATA]: async ({ commit }, { params }) => {
      const result = await getGridData(params);
      commit(SET_GRID, { data: result });
    },
  },
};
