/**
 * @type {Object<string, {
 *  label: string,
 *  baseRoute: string,
 *  icon: string,
 *  creatable: boolean
 * }>}
 */
export const ENTITY_MAP = {
  users: {
    label: 'Пользователь',
    baseRoute: 'users',
    icon: 'mdi-human',
    creatable: false,
  },
  sb_schemas: {
    label: 'Модель',
    baseRoute: 'schemas',
    icon: 'mdi-table',
    creatable: true,
  },
  sb_prototypes: {
    label: 'Прототип',
    baseRoute: 'prototypes',
    icon: 'mdi-set-none',
    creatable: true,
  },
  sb_steps: {
    label: 'Степ',
    baseRoute: 'steps',
    icon: 'mdi-shoe-print',
    creatable: true,
  },
  flows: {
    label: 'Карта',
    baseRoute: 'flow',
    icon: 'mdi-sitemap-outline',
    creatable: true,
  },
};

export const SBDialogTitles = {
  schemas: 'Новая модель',
  prototypes: 'Новый прототип',
  steps: 'Новый степ',
  default: 'Новый объект',
};
