import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import GridView from '@/views/GridView';
import LoginView from '@/views/LoginView';
import StepBuilder from '@/components/EntityBuilder';
import FlowsView from '@/views/FlowsView';

Vue.use(VueRouter);

const routes = [
  {
    path: '/account/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/flow/:flowId',
    name: 'Flows',
    component: FlowsView,
  },
  {
    path: '/:sbType/:entityId',
    name: 'SBEntity',
    component: StepBuilder,
  },
  {
    path: '/',
    name: 'Grid',
    component: GridView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath === router.currentRoute.fullPath) {
    next();
  }

  if (to.name === 'SBEntity' || to.name === 'Flows') {
    store.commit('tabs/addTab', { tab: { fullPath: to.fullPath, name: '' } });
  }
  next();
});

export default router;
