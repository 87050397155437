import axios from 'axios';

const baseConfig = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

export let http;

export async function createHttp() {
  http = axios.create(baseConfig);

  return http;
}
