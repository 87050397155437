import server from '@/api/server';

/**
 * GET запрос на сервер
 */
export async function serverGet(method) {
  let res = await server.get(method);
  return res;
}

/**
 * POST запрос на сервер
 */
export async function serverPost(method, payload) {
  let res = await server.post(method, payload);
  return res;
}

/**
 * Данные текущего пользователя
 */
export async function getUserInfo(payload) {
  if (payload) {
    let res = await server.post('/help/get_user_info', payload);
    return res;
  } else {
    let res = await server.get('/help/get_user_info');
    return res;
  }
}

/**
 * Данные грида
 */
export async function getGridData(payload) {
  let res = await server.post('/grid/get', payload);
  return res;
}

/**
 * Список всех типов допустимый для конкретной карты
 * @param {string} flowid идентификатор карты
 */
export async function getTypes(flowid) {
  let res = await server.post('/help/get_types', { flowid });
  return res;
}

/**
 * Объект конкретной объектной модели
 * @param {string} object идентификатор получаемой модели
 * @param {string} type тип модели
 */
export async function getModel(object, type = 'default') {
  let res = await server.post('/model/get', { object, type });
  return res;
}
