<template>
  <v-app v-if="!isDev">
    <AppBar v-if="!isLogin" />
    <v-container>
      <Tabs v-if="!isLogin" />
      <KeepAlive>
        <router-view />
      </KeepAlive>
    </v-container>
    <Loading />
    <Alert />
  </v-app>
  <v-app v-else>
    <DevView />
  </v-app>
</template>

<script>
import Alert from '@/components/common/Alert';
import Loading from '@/components/common/Loading';
import AppBar from '@/components/AppBar';
import Tabs from '@/components/Tabs';
import server from '@/api/server';
import DevView from '@/views/DevView';

export default {
  name: 'App',

  components: {
    Tabs,
    AppBar,
    Loading,
    Alert,
    DevView,
  },

  data: () => ({
    isDev: false,
  }),
  computed: {
    isLogin() {
      return this.$route.name === 'Login';
    },
  },
  created() {
    server.defaults.params.tnx = localStorage.getItem('tnx');
    console.log('ENV', process.env.NODE_ENV);
  },
};
</script>

<style lang="scss">
@import 'styles/main.scss';
</style>
