import { getModel, serverGet, serverPost } from '@/api';
import Vue from 'vue';

export default async function modelForm(modelName, initialData) {
  const _model = (await getModel(modelName))?.model;
  const _values = {};
  const _lists = {};
  for (let key in _model) {
    _values[key] = (initialData && initialData[key]) ?? (_model[key]?.data?.default || '');
    _lists[key] = [];
  }
  if (initialData) {
    for (let key in _model) {
      if (_values[key] == null || _values[key] === '') {
        continue;
      }

      const modelData = _model[key]?.data;
      if (modelData && modelData.type === 'selection') {
        const modelSelection = modelData.selection;
        const selectionType = modelSelection.type;
        if (selectionType === 'list') {
          _lists[key] = modelSelection.list;
        } else if (selectionType === 'method') {
          const modelMethod = modelSelection.method;
          const requestFields = modelSelection.method_data && Object.keys(modelSelection.method_data);
          const requestMethodData = modelSelection.method_data;
          let requestData = undefined;
          if (Array.isArray(requestFields)) {
            requestData = {};
            requestFields.forEach((field) => {
              const modelField = requestMethodData[field].field;
              requestData[field] = _values[modelField] || '';
            });
          }
          const resultList = requestData ? await serverPost(modelMethod, requestData) : await serverGet(modelMethod);
          _lists[key] = resultList;
        }
      }
    }
  }

  return {
    namespaced: true,
    state: () => ({
      model: _model,
      values: _values,
      lists: _lists,
    }),
    getters: {
      data: (state) => {
        const data = {};
        const { model, values } = state;
        for (let key in model) {
          if (model[key].type === 'number') {
            data[key] = parseInt(values[key]);
          } else if (model[key].type === 'boolean') {
            data[key] = !!values[key];
          } else {
            data[key] = values[key];
          }
        }
        return data;
      },
      fieldBindRules: (state) => (fieldName) => {
        let bindRules = [];
        try {
          const field = state.model[fieldName];
          const fieldRules = field?.rules;
          const ruleIndex = fieldRules.findIndex((rule) => rule.type === 'bind');
          bindRules = fieldRules[ruleIndex]?.rule;
        } catch (e) {
          bindRules = [];
        }
        return bindRules;
      },
      fieldBinds: (state, getters) => (fieldName) => {
        let fieldBinds = {};
        try {
          const bindRules = getters.fieldBindRules(fieldName);
          if (!Array.isArray(bindRules)) return {};
          bindRules.forEach((rule) => {
            if (rule.type === 'field') {
              fieldBinds[rule.field] = state.values[rule.field] || '';
            }
          });
        } catch (e) {
          fieldBinds = {};
        }
        return fieldBinds;
      },
      isFieldVisible: (state, getters) => (fieldName) => {
        // events / values
        let flag = true;
        try {
          const field = state.model[fieldName];
          const fieldRules = field?.rules;
          const fieldBindRules = getters.fieldBindRules(fieldName);
          const fieldBinds = getters.fieldBinds(fieldName);

          if (fieldRules && fieldRules.findIndex((rule) => rule.type === 'hidden') !== -1) {
            flag = false;
          }

          if (!Array.isArray(fieldBindRules)) {
            return flag;
          }
          fieldBindRules.forEach((rule) => {
            if (rule.events && rule.events.find((ev) => ev === 'input')) {
              if (!fieldBinds[rule.field]) {
                flag = false;
              }
            }
            if (rule.values) {
              if (!rule.values.includes(fieldBinds[rule.field])) {
                flag = false;
              }
            }
          });
        } catch (e) {
          console.log('visible');
          console.log(e);
        }
        return flag;
      },
      isFieldRequired: (state) => (fieldName) => {
        try {
          const field = state.model[fieldName];
          const fieldRules = field?.rules;
          if (fieldRules) {
            const ruleIndex = fieldRules.findIndex((rule) => rule.type === 'required');
            if (ruleIndex !== -1) {
              return true;
            }
          }
        } catch (e) {
          console.log('required');
          console.log(e);
        }
        return false;
      },
      isFieldReadonly: (state) => (fieldName) => {
        try {
          const field = state.model[fieldName];
          const fieldRules = field?.rules;
          if (fieldRules) {
            const ruleIndex = fieldRules.findIndex((rule) => rule.type === 'readonly');
            if (ruleIndex !== -1) {
              return true;
            }
          }
        } catch (e) {
          console.log('readonly');
          console.log(e);
        }
        return false;
      },
    },
    mutations: {
      setModelField: (state, { name, value }) => {
        try {
          Vue.set(state.values, name, value);
        } catch (e) {
          console.log(e);
        }
      },
    },
    actions: {
      updateModelList: async ({ state }, fieldName) => {
        const modelData = state.model[fieldName]?.data;
        if (modelData && modelData.type === 'selection') {
          const modelSelection = modelData.selection;
          const selectionType = modelSelection.type;
          if (selectionType === 'list') {
            Vue.set(state.lists, fieldName, modelSelection.list);
          } else if (selectionType === 'method') {
            const modelMethod = modelSelection.method;
            const requestFields = modelSelection.method_data && Object.keys(modelSelection.method_data);
            const requestMethodData = modelSelection.method_data;
            let requestData = undefined;
            if (Array.isArray(requestFields)) {
              requestData = {};
              requestFields.forEach((field) => {
                const modelField = requestMethodData[field].field;
                requestData[field] = state.values[modelField] || '';
              });
            }
            const resultList = requestData ? await serverPost(modelMethod, requestData) : await serverGet(modelMethod);
            Vue.set(state.lists, fieldName, resultList);
          }
        }
      },
      invalidateFields({ state, getters }, fieldName) {
        const fields = Object.keys(state.model);
        for (const field of fields) {
          if (field === fieldName) {
            continue;
          }
          const fieldBindRules = getters.fieldBindRules(field);
          if (fieldBindRules) {
            const idx = fieldBindRules.findIndex((r) => r.type === 'field' && r.field === fieldName);
            if (idx !== -1) {
              Vue.set(state.values, field, '');
              Vue.set(state.lists, field, []);
            }
          }
        }
      },
    },
  };
}
