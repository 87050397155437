<template>
  <v-main>
    <v-container class="mt-16">
      <FBRLogin />
    </v-container>
  </v-main>
</template>

<script>
import FBRLogin from '@/components/login/FBRLogin';

export default {
  name: 'LoginView',

  components: { FBRLogin },
};
</script>
