import { SET_NODE_NEW_COORDS, SET_NODE_INIT, SET_IS_TASK_EXE } from '@/store/mutations';
import { INIT_NODE } from '@/store/actions';
import store from '@/store';
import modelForm from '@/store/modules/modelForm';

const initState = (id, name, description, X, Y, oid, task_id, data, task_data, error_handler_data, type, is_task_exe, has_task, entityModuleName) => {
  return {
    id,
    name,
    description,
    X,
    Y,
    oid,
    task_id,
    data,
    task_data: task_data ?? {},
    error_handler_data: error_handler_data ?? {},
    type,
    is_task_exe,
    has_task,
    entityModuleName,
    nodeInit: false,
  };
};

export default class Node {
  constructor({ id, data, X, Y, oid, taskid: task_id, task_data, error_handler_data, type = 'step', is_task_exe, has_task }, entityModuleName) {
    data = data ?? { name: '', description: '' };
    const { name, description } = data;
    this.namespaced = true;
    this.state = initState(id, name, description, X, Y, oid, task_id, data, task_data, error_handler_data, type, is_task_exe, has_task, entityModuleName);
    this.mutations = {
      [SET_NODE_INIT]: (state) => (state.nodeInit = true),
      [SET_IS_TASK_EXE]: (state, payload) => (state.is_task_exe = payload),
      [SET_NODE_NEW_COORDS]: (state, payload) => {
        // {x, y}
        state.X = payload.x;
        state.Y = payload.y;
      },
    };
    this.actions = {
      [INIT_NODE]: async ({ commit, state: { data, task_data, error_handler_data, type, is_task_exe, has_task, entityModuleName, nodeInit } }) => {
        if (nodeInit) {
          return;
        }

        const moduleName = `modelForm_${entityModuleName}`;
        if (type === 'root') {
          if (!store.hasModule(`${moduleName}/root_model`)) store.registerModule(`${moduleName}/root_model`, await modelForm('flow', data));
          if (!store.hasModule(`${moduleName}/error_handler_model`)) store.registerModule(`${moduleName}/error_handler_model`, await modelForm('flow_err', error_handler_data));
        } else {
          if (!store.hasModule(`${moduleName}/step_model`)) store.registerModule(`${moduleName}/step_model`, await modelForm('step', data));
          if (has_task) {
            const commonTaskData = { name: task_data.name, description: task_data.description }; // FIXME: Kostyl
            const taskData = !is_task_exe ? task_data : commonTaskData;
            const taskExeData = is_task_exe ? task_data : commonTaskData;
            if (!store.hasModule(`${moduleName}/task_model`)) store.registerModule(`${moduleName}/task_model`, await modelForm('task', taskData));
            if (!store.hasModule(`${moduleName}/task_exe_model`)) store.registerModule(`${moduleName}/task_exe_model`, await modelForm('task_special_exe', taskExeData));
          }
        }

        commit(SET_NODE_INIT);
      },
    };
  }
}
