<template>
  <v-card>
    <v-card-title>
      {{ cardTitle }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12">
            <v-text-field v-model="schemaName" label="Название модели" placeholder="Название модели" :rules="[rules.required]" />
          </v-col>
          <template v-for="(field, i) in fields">
            <v-col :key="`${field.id}_name`" cols="5">
              <v-text-field v-model="field.name" label="Название поля" placeholder="Название поля" :rules="[rules.required]" dense />
            </v-col>
            <v-col :key="`${field.id}_dataType`" cols="5">
              <v-select v-model="field.dataType" label="Тип данных" :items="modelTypes" item-value="name" item-text="name" :rules="[rules.required]" dense />
            </v-col>
            <!--<v-col :key="`${field.id}_extra`" cols="1">
              <v-checkbox v-model="field.extra" append-icon="mdi-cog" class="mt-0" dense />
            </v-col>-->
            <v-col :key="`${field.id}_remove`" cols="1">
              <v-btn dark class="red darken-2" @click="removeField(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <template v-if="field.extra">
              <v-col :key="`${field.id}_description`" cols="6">
                <v-text-field v-model="field.description" label="Описание содержимого" placeholder="Описание..." dense />
              </v-col>
              <v-col :key="`${field.id}_maxLength`" cols="6">
                <v-text-field v-model="field.maxLength" :disabled="!hasLength(field.dataType)" type="number" label="Максимальная длина содержимого" placeholder="10" dense />
              </v-col>
            </template>
          </template>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn rounded class="primary" @click="addEmptyField">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="error" @click="deleteEntity">Удалить</v-btn>
      <v-btn class="primary" @click="submitEntity">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { GET_MODEL_TYPES } from '@/store/actions';

import { buildSBModel, deleteSBEntity, getSBEntity } from '@/api';
import { modelFieldFactory, fetchModelFieldFactory, schemaFieldToInitObj } from '@/utils/SBModelFieldFactories';
import { ENTITY_MAP, SBDialogTitles } from '@/utils/constants';
import { required } from '@/utils/rules';

export default {
  name: 'SBModelForm',
  data: () => ({
    oldEntityId: undefined,
    schemaName: '',
    fields: [modelFieldFactory({ id: 1 })],
    lastFieldId: 1,
    rules: {
      required: required('Обязательное поле'),
    },
  }),
  computed: {
    ...mapState('stepBuilder', ['modelTypes']),
    ...mapGetters('stepBuilder', ['modelTypesLoaded']),
    activeEntityId() {
      return this.$route.params.entityId;
    },
    cardTitle() {
      return this.schemaName || SBDialogTitles.schemas;
    },
    isNew() {
      return this.activeEntityId === 'new';
    },
  },
  watch: {
    activeEntityId: {
      async handler(newVal) {
        if (!newVal || newVal === this.oldEntityID) {
          return;
        }

        this.oldEntityId = newVal;
        await this.initEntity(newVal);
        this.setTabName({
          fullPath: this.$route.fullPath,
          newName: `Модель - ${this.cardTitle || 'Безымянный'}`,
        });
      },
      immediate: true,
    },
  },
  async created() {
    if (!this.modelTypesLoaded) {
      this[GET_MODEL_TYPES]();
    }
  },
  methods: {
    ...mapActions('stepBuilder', [GET_MODEL_TYPES]),
    ...mapMutations('tabs', ['setTabName', 'removeTab']),

    addEmptyField() {
      ++this.lastFieldId;
      this.fields.push(modelFieldFactory({ id: this.lastFieldId }));
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    hasLength(dataType) {
      return this.modelTypes?.find((type) => type.name === dataType)?.supports_length || false;
    },
    async submitEntity() {
      if (this.$refs.form.validate()) {
        const payload = {
          name: this.schemaName,
          schema: this.fields.map((field) => fetchModelFieldFactory(field)),
          updating: !this.isNew,
        };
        const result = await buildSBModel(payload);

        // Change current route with new id
        if (this.isNew) {
          this.removeTab({ fullPath: this.$route.fullPath });
          this.$router.push(`/${ENTITY_MAP.sb_schemas.baseRoute}/${result.id}`);
        }
      }
    },
    async deleteEntity() {
      await deleteSBEntity({ type: 'schema', id: this.activeEntityId });
      this.removeTab({ fullPath: this.$route.fullPath });
    },
    async initEntity(id) {
      if (id && id !== 'new') {
        const schema = await getSBEntity({ type: 'schema', id });
        this.schemaName = schema.name;
        this.fields = schema.schema.map((obj) => modelFieldFactory(schemaFieldToInitObj(++this.lastFieldId, obj)));
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
