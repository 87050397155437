<template>
  <div>
    <v-sheet class="mx-auto" max-width="420">
      <h2 class="text-center mb-8">Вход в личный кабинет</h2>
      <v-sheet class="px-14 py-8 mb-6" rounded color="grey lighten-3">
        <FBRLoginInit v-if="isInit" @init="init" />
        <FBRLoginConfirm v-if="isConfirm" :resend-delay="resendDelay" :masked-phone="maskedPhone" @confirm="confirm" @refresh="refresh" />
      </v-sheet>
      <div class="font--action-small font--color_light text-center mb-6">
        <router-link class="text-link text-decoration-none" to="/account/recovery">Восстановить пароль</router-link>
      </div>
    </v-sheet>
    <v-snackbar v-model="resendAlert" top color="error" right>
      Повторная отправка кода подтверждения возможна через {{ resendDelay }}с.
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="resendAlert = false"> Закрыть </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as loginAPI from '@/api/sessions';
import FBRLoginInit from './FBRLoginInit';
import FBRLoginConfirm from './FBRLoginConfirm';
import server from '@/api/server';

const INIT = 'init';
const CONFIRM = 'confirm';

export default {
  name: 'FBRLogin',

  components: { FBRLoginInit, FBRLoginConfirm },
  data() {
    return {
      smsid: null,
      login: null,
      stage: INIT,
      resendDelay: 180,
      resendAlert: false,
    };
  },
  computed: {
    isInit() {
      return this.stage === INIT;
    },
    isConfirm() {
      return this.stage === CONFIRM;
    },
    maskedPhone() {
      return this.login ? '+7***' + this.login.substring(8) : '';
    },
  },

  methods: {
    async init({ login, pass }) {
      const { response } = await loginAPI.init({ login, pass });
      if (response) {
        this.login = login;
        this.smsid = response.smsid;
        this.stage = CONFIRM;
      }
    },

    async confirm(code) {
      const data = await loginAPI.confirm({ code, smsid: this.smsid });
      if (data.tnx) {
        localStorage.setItem('tnx', data.tnx);
        server.defaults.params.tnx = data.tnx;
        return this.$router.push('/');
      }
    },
    async refresh() {
      const { ok, response } = await loginAPI.refresh(this.login);

      if (ok) {
        const { smsid } = response;
        this.smsid = smsid;
      }
    },
  },
};
</script>
