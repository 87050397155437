<template>
  <v-row>
    <v-tabs class="flow-tabs">
      <v-tab to="/" dense>
        <v-icon color="primary">mdi-home</v-icon>
      </v-tab>
      <v-tab v-for="tab in tabs" :key="tab.fullPath" :to="tab.fullPath">
        {{ tab.name }}
        <v-btn x-small text @click.prevent.stop.once="closeTab(tab.fullPath)">
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </v-tab>
      <v-divider class="ml-4 mt-2" vertical></v-divider>
      <v-menu right min-width="100px" rounded offset-x>
        <template v-slot:activator="{ on }">
          <v-btn icon plain :ripple="false" x-large v-on="on">
            <v-icon color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(entity, key) in entities" :key="key">
            <v-btn @click="createNewEntity(key)">
              {{ entity.label }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-tabs>
  </v-row>
</template>

<script>
import { ENTITY_MAP } from '@/utils/constants';
import { mapMutations, mapState } from 'vuex';
import Guid from '@/utils/Guid';
import { saveMap } from '@/api';

export default {
  name: 'Tabs',
  computed: {
    ...mapState('tabs', ['tabs']),
    entities() {
      return Object.keys(ENTITY_MAP)
        .filter((key) => ENTITY_MAP[key].creatable)
        .reduce((res, key) => ((res[key] = ENTITY_MAP[key]), res), {});
    },
  },
  methods: {
    ...mapMutations('tabs', ['removeTab']),
    closeTab(fullPath) {
      this.removeTab({ fullPath });
    },
    getTabName(tab) {
      return tab.name || tab.fullPath.split('/').slice(1).join('/');
    },
    async createNewEntity(key) {
      const entity = ENTITY_MAP[key];
      if (entity) {
        if (key === 'flows') {
          await this.createNewFlow();
        } else {
          this.$router.push(`/${entity.baseRoute}/new`);
        }
      }
    },
    async createNewFlow() {
      const mapObj = {
        map: {
          nodes: [
            {
              id: Guid.newid,
              type: 'root',
              X: 0,
              Y: 0,
              oid: Guid.newid,
              data: {
                name: `Новая карта - ${Guid.newid.slice(0, 8)}`,
                path_object: 'nopath',
                is_sync: true,
                max_sec_resp: 3,
                description: 'Новая карта',
              },
            },
          ],
          edges: [],
        },
      };
      const res = await saveMap(mapObj);
      if (res) {
        const { flowid } = res;
        this.$router.push(`/flow/${flowid}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flow-tabs {
  height: 100%;
  margin-top: $header-height;
  margin-bottom: 15px;
}
</style>
