import server from '@/api/server';

export async function getSBModelTypes() {
  let res = await server.get('/step_builder/get_schema_types');
  return res;
}

export async function getSBSchemas() {
  let res = await server.get('/step_builder/get_schemas');
  return res;
}

export async function getSBPrototypes() {
  let res = await server.get('/step_builder/get_prototypes');
  return res;
}

export async function getSBSteps() {
  let res = await server.get('/step_builder/get_steps');
  return res;
}

export async function getSBEntity(payload) {
  // { type: "schema" | "prototype" | "step", id }
  let res = await server.post('/step_builder/get', payload);
  return res;
}

/**
 * Создать или изменить схему степ-билдера
 */
export async function buildSBModel(payload) {
  let res = await server.post('/step_builder/build_schema', payload);
  return res;
}

/**
 * Создать или изменить прототип степ-билдера
 */
export async function buildSBPrototype(payload) {
  let res = await server.post('/step_builder/build_prototype', payload);
  return res;
}

/**
 * Создать или изменить степ степ-билдера
 */
export async function buildSBStep(payload) {
  let res = await server.post('/step_builder/build_step', payload);
  return res;
}

export async function deleteSBEntity(payload) {
  // { type: "schema" | "prototype" | "step", id }
  let res = await server.post('/step_builder/delete', payload);
  return res;
}
