// flow actions

export const GET_MAP = 'getMap';
export const SAVE = 'save';
export const PUBLISH_FLOW = 'publishFlow';

// node actions

export const INIT_NODE = 'initNode';

// edge actions

export const INIT_EDGE = 'initEdge';

// grid actions

export const GET_GRID_DATA = 'getGridData';

// stepBuilder actions

export const GET_MODEL_TYPES = 'getModelTypes';
export const GET_STEP_SETTINGS = 'getStepSettings';
