export const required = (msg) => (v) => !!v || v === false || msg;
export const min = (count) => (v) => v?.length >= count || !v || `Минимальная длина: ${count}`;
export const max = (count) => (v) => v?.length <= count || !v || `Максимальная длина: ${count}`;

// import { patternEmail } from '@/utils/helpers';

//
export const phone = (v) => v.replace(/\D/g, '').length === 11 || 'Введите номер телефона';
// export const email = (v) => patternEmail.test(v) || 'Введите корректный e-mail';
// export const digits = (v) => !/[^0-9]/.test(v) || 'Введите только цифры';
//
// /**
//  * Валидация данных в виде правил (rules) для vuetify
//  *
//  * [info](http://www.kholenkov.ru/data-validation/)
//  * [github](https://github.com/Kholenkov/js-data-validation/blob/master/data-validation.js)
//  * [vuetify rules](https://vuetifyjs.com/en/components/forms/#rules)
//  */
//
// export const bik = (bik) => {
//   if (!bik) return true; // Пустое значение не проверяем!
//   if (/[^0-9]/.test(bik)) return 'БИК может состоять только из цифр';
//   if (bik.length !== 9) return 'БИК может состоять только из 9 цифр';
//   return true;
// };
//
// export const kpp = (kpp) => {
//   if (!kpp) return true; // Пустое значение не проверяем!
//   if (kpp.length !== 9) return 'КПП может состоять только из 9 знаков';
//   if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) return 'Неправильный формат КПП';
//   return true;
// };
//
// export const snils = (snils) => {
//   if (!snils) return true; // Пустое значение не проверяем!
//   if (/[^0-9]/.test(snils)) return 'СНИЛС может состоять только из цифр';
//   if (snils.length !== 11) return 'СНИЛС может состоять только из 11 цифр';
//
//   const sum = [9, 8, 7, 6, 5, 4, 3, 2, 1].reduce((sum, n, i) => sum + n * snils[i], 0);
//   let controlSum = 0;
//
//   if (sum < 100) {
//     controlSum = sum;
//   } else if (sum > 101) {
//     controlSum = parseInt(sum % 101);
//
//     if (controlSum === 100) {
//       controlSum = 0;
//     }
//   }
//   const isControlSumCorrect = controlSum === parseInt(snils.slice(-2));
//
//   return isControlSumCorrect ? true : 'Неправильное контрольное число';
// };
//
// export const ogrn_ul = (ogrn) => {
//   if (!ogrn) return true; // Пустое значение не проверяем!
//
//   if (/[^0-9]/.test(ogrn)) {
//     return 'ОГРН может состоять только из цифр';
//   }
//   if (ogrn.length !== 13) {
//     return 'ОГРН может состоять только из 13 цифр';
//   }
//
//   const controlSum = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
//   const isControlSumCorrect = controlSum === parseInt(ogrn[12]);
//
//   return isControlSumCorrect ? true : 'Неправильное контрольное число';
// };
//
// export const ogrn_ip = (ogrnip) => {
//   if (!ogrnip) return true; // Пустое значение не проверяем!
//   if (/[^0-9]/.test(ogrnip)) return 'ОГРНИП может состоять только из цифр';
//   if (ogrnip.length !== 15) return 'ОГРНИП может состоять только из 15 цифр';
//
//   const controlSum = parseInt((parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1));
//   const isControlSumCorrect = controlSum === parseInt(ogrnip[14]);
//
//   return isControlSumCorrect ? true : 'Неправильное контрольное число';
// };
//
// export const ogrn = (ogrn) => {
//   if (!ogrn) return true; // Пустое значение не проверяем!
//
//   if (/[^0-9]/.test(ogrn)) {
//     return 'ОГРН/ОГРНИП может состоять только из цифр';
//   }
//
//   if (ogrn.length !== 13 && ogrn.length !== 15) {
//     return 'Неверное количество знаков';
//   }
//
//   if (ogrn.length === 13) {
//     return ogrn_ul(ogrn);
//   }
//   if (ogrn.length === 15) {
//     return ogrn_ip(ogrn);
//   }
// };
//
// export const inn_ul = (inn) => {
//   if (!inn) return true; // Пустое значение не проверяем!
//
//   if (/[^0-9]/.test(inn)) {
//     return 'ИНН может состоять только из цифр';
//   }
//   if (inn.length !== 10) {
//     return 'ИНН может состоять только из 10 цифр';
//   }
//
//   const controlSum = [2, 4, 10, 3, 5, 9, 4, 6, 8].reduce((res, n, i) => res + n * inn[i], 0);
//   const isControlSumCorrect = parseInt((controlSum % 11) % 10) === parseInt(inn[9]);
//
//   return isControlSumCorrect ? true : 'Неправильное контрольное число';
// };
//
// export const inn_ip = (inn) => {
//   if (!inn) return true; // Пустое значение не проверяем!
//
//   if (/[^0-9]/.test(inn)) {
//     return 'ИНН может состоять только из цифр';
//   }
//   if (inn.length !== 12) {
//     return 'ИНН может состоять только из 12 цифр';
//   }
//   const controlSum11 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8].reduce((res, n, i) => res + n * inn[i], 0);
//   const controlSum12 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8].reduce((res, n, i) => res + n * inn[i], 0);
//
//   const isControlSumCorrect =
//     parseInt((controlSum11 % 11) % 10) === parseInt(inn[10]) &&
//     parseInt((controlSum12 % 11) % 10) === parseInt(inn[11]);
//
//   return isControlSumCorrect ? true : 'Неправильное контрольное число';
// };
//
// export const inn = (inn) => {
//   if (!inn) return true; // Пустое значение не проверяем!
//
//   if (/[^0-9]/.test(inn)) {
//     return 'ИНН может состоять только из цифр';
//   }
//   if (![10, 12].includes(inn.length)) {
//     return 'ИНН может состоять только из 10 или 12 цифр';
//   }
//
//   if (inn.length === 10) return inn_ul(inn);
//   if (inn.length === 12) return inn_ip(inn);
// };
//
// // Для валидации по контрольной сумме нужен bic
// export const acc = (acc, bic) => {
//   if (!acc) return true;
//   if (/[^0-9]/.test(acc)) return 'Номер счета может состоять только из цифр';
//   if (!bic) return acc.length === 20 || 'Номер счета может состоять только из 20 цифр';
//
//   if (acc.length !== 20) return 'Номер счета может состоять только из 20 цифр';
//
//   const factors = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
//   const bicAcc = bic.slice(-3) + acc;
//   const controlSum = factors.reduce((sum, n, i) => sum + n * (bicAcc[i] % 10), 0);
//   const isControlSumCorrect = controlSum % 10 === 0;
//
//   return isControlSumCorrect ? true : 'Неправильное контрольное число';
// };
