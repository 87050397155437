export const modelFieldFactory = (initObj) => {
  const emptyObject = {
    id: 0,
    name: '',
    dataType: '',
    extra: false,
    maxLength: undefined,
    description: undefined,
  };
  return { ...emptyObject, ...initObj };
};

export const schemaFieldToInitObj = (id, oldObj) => {
  return {
    id,
    name: oldObj?.field,
    dataType: oldObj?.type,
    extra: oldObj?.length !== undefined || !!oldObj.description,
    maxLength: oldObj.length,
    description: oldObj.description,
  };
};

export const fetchModelFieldFactory = (rawField) => {
  try {
    const { name: field, dataType: type, maxLength: max_length, description, extra } = rawField;
    return {
      field,
      type,
      ...(extra && max_length && { max_length }),
      ...(extra && description && { description }),
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};
