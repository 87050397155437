var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0"},[(_vm.hasTooltip)?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","close-on-content-click":false,"top":"","nudge-top":"10","content-class":"elevation-1","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-n4 ml-n4 float-left",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,false,1234354553)},[_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"flat":"","height":"100%","width":"100%"}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")])],1)],1):_vm._e(),_c('v-card',{class:{
      'entity-error': _vm.hasError,
      'entity-wait': _vm.hasWait,
      'entity-success': _vm.hasSuccess,
      'entity-active': _vm.isActive,
    },attrs:{"id":_vm.id,"max-width":"200","flat":""},on:{"dblclick":_vm.openSidePanel},nativeOn:{"!mouseup":function($event){return (function (e) { return _vm.setLastMove(e); })($event)}}},[_c('div',{staticClass:"header d-flex justify-space-between align-center pl-4 pr-8 py-3"},[(_vm.root)?_c('div',{staticClass:"root d-flex justify-center align-center pl-0 mr-1"},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-home")])],1):_vm._e(),(_vm.step)?_c('div',{staticClass:"step d-flex justify-center align-center pl-0 mr-2"},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-flag-variant")])],1):_vm._e(),_c('v-card-text',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.nodeName))])],1),_c('v-card-title',{staticClass:"pb-1 text-body-2"},[_vm._v(_vm._s(_vm.nodeType))]),_c('v-card-text',[_vm._v(_vm._s(_vm.nodeDescription))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }