import router from '@/router';

const initState = {
  tabs: [], // { fullPath, name? };
};
export const tabs = {
  namespaced: true,
  state: () => initState,
  mutations: {
    addTab: (state, { tab }) => {
      const index = state.tabs.findIndex((t) => t?.fullPath === tab?.fullPath);
      if (index === -1) {
        state.tabs.push(tab);
      }
    },
    removeTab: (state, { fullPath }) => {
      const index = state.tabs.findIndex((tab) => tab.fullPath === fullPath);
      if (index !== -1) {
        state.tabs.splice(index, 1);
        if (router.currentRoute.fullPath === fullPath) {
          router.push({ name: 'Grid' });
        }
      }
    },
    setTabName: (state, { fullPath, newName }) => {
      const _tabs = state.tabs;
      const index = _tabs.findIndex((tab) => tab.fullPath === fullPath);
      if (index !== -1) {
        _tabs[index].name = newName;
        state.tabs = _tabs;
      }
    },
  },
};
