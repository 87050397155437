export const SBStepFactory = ({
  id,
  name,
  description,
  prototypeId,
  parentId,
  forbiddenConnections,
  settings,
  sync,
  async,
  defaultStepProcedure,
  defaultServiceName,
  defaultExecution,
  groupName,
  hasTask,
  updating,
}) => {
  const _settings = [];
  settings?.forEach((field) => {
    if (field.value !== undefined) _settings.push({ field: field.name, value: field.value });
  });
  return {
    ...(id && { id }),
    name,
    ...(description && { description }),
    ...(forbiddenConnections?.length && { forbidden_connections: forbiddenConnections }),
    prototypeid: prototypeId,
    ...(parentId && { parentid: parentId }),
    settings: _settings,
    sync,
    async,
    ...(defaultStepProcedure && { default_step_procedure: defaultStepProcedure }),
    ...(defaultServiceName && { default_service_name: defaultServiceName }),
    ...(defaultExecution && { default_execution: defaultExecution }),
    ...(groupName && { group_name: groupName }),
    has_task: hasTask,
    updating: updating,
  };
};
