<template>
  <div v-if="activeFlowId" class="top-controls">
    <v-btn outlined class="top-controls__button" @click="saveFlow">
      <v-icon>mdi-content-save</v-icon>
    </v-btn>
    <v-btn outlined class="top-controls__button" @click="openRefreshModal">
      <v-icon>mdi-refresh</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { SET_REFRESH_FLOW_DIALOG, TOGGLE_LOCK } from '@/store/mutations';
import { SAVE } from '@/store/actions';

export default {
  name: 'TopControls',
  computed: {
    ...mapState('flows', ['activeFlowId']),
    ...mapGetters('flows', ['moduleName']),
    locked: {
      get() {
        return this.$store.state[this.moduleName].locked;
      },
      set(newVal) {
        this.$store.dispatch(`${this.moduleName}/${TOGGLE_LOCK}`, newVal);
      },
    },
  },
  methods: {
    ...mapMutations('flows', [SET_REFRESH_FLOW_DIALOG]),
    openRefreshModal() {
      this[SET_REFRESH_FLOW_DIALOG](true);
    },
    saveFlow() {
      this.$store.dispatch(`${this.moduleName}/${SAVE}`);
    },
  },
};
</script>

<style scoped lang="scss">
.top-controls {
  width: 100%;
  justify-content: center;
  display: flex;

  &__button {
    width: 36px;
    min-width: unset;

    &:nth-child(3) {
      margin-right: 40px;
    }
  }

  &__button + &__button {
    margin-left: 15px;
  }
}
</style>
