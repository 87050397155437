<template>
  <v-form v-model="valid" @submit.prevent="initLogin">
    <v-text-field v-model="login" v-mask="'+7(9##) ###-##-##'" background-color="#ffffff" label="Номер телефона" placeholder=" " outlined autofocus :rules="phoneRules" type="text"></v-text-field>
    <v-text-field
      v-model="pass"
      :rules="passRules"
      background-color="#ffffff"
      hide-details
      label="Пароль"
      placeholder=" "
      outlined
      prepend-inner-icon="mdi-lock-outline"
      :type="show ? 'text' : 'password'"
      class="mb-8"
    >
      <template v-slot:append>
        <v-icon v-if="show" tabindex="-1" @click="show = !show">mdi-eye</v-icon>
        <v-icon v-else tabindex="-1" @click="show = !show">mdi-eye-off</v-icon>
      </template>
    </v-text-field>
    <v-btn :disabled="!valid" x-large block color="primary" type="submit">Продолжить</v-btn>
  </v-form>
</template>

<script>
import { mask } from 'vue-the-mask';
import { required, phone } from '@/utils/rules';
import { clearPhone } from '@/utils/helpers';

export default {
  name: 'FBRLoginInit',

  directives: { mask },
  data() {
    return {
      phoneRules: [required('Введите номер телефона'), phone],
      passRules: [required('Введите пароль')],
      login: '',
      pass: '',
      show: false,
      valid: false,
    };
  },
  computed: {
    loginDigits() {
      return clearPhone(this.login);
    },
  },
  methods: {
    initLogin() {
      this.$emit('init', { login: this.loginDigits, pass: this.pass });
    },
  },
};
</script>
