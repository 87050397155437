<template>
  <v-form ref="form" class="pa-4" lazy-validation @submit.prevent="updateFilters">
    <v-row v-for="filter in filters" :key="filter.column" no-gutters align="center" class="pb-4">
      <v-col cols="3" class="px-1">
        <v-checkbox v-model="filter.active" :label="filter.text" dense hide-details></v-checkbox>
      </v-col>
      <template v-if="isSelect(filter)">
        <v-col v-if="isMultiple(filter)" cols="1" class="px-1">
          <v-checkbox v-model="filter.not" :disabled="!filter.active" label="НЕ" dense hide-details />
        </v-col>
        <v-col :cols="isMultiple(filter) ? 7 : 8" class="px-1">
          <v-select
            v-model="filter.value"
            :rules="isEnum(filter) ? RULES.ENUM(filter) : RULES.MULTIPLE(filter)"
            clearable
            :disabled="!filter.active"
            :items="filter.column === 'state' ? getFilterOptions(filter) : filter.options"
            :multiple="isMultiple(filter)"
            item-text="label"
            item-value="name"
            dense
            hide-details
            class="select"
            @change="filter.column === 'type' ? setStatesDependingOnTypes(filter) : null"
          >
            <template v-if="isMultiple(filter) && filter.value && filter.value.length" #selection="{ item, index }">
              <v-chip v-if="filter.value.length === 1">
                <span>{{ item.description }}</span>
              </v-chip>
              <span v-else-if="index === 0" class="grey--text text-caption"> Выбрано несколько ({{ filter.value.length }}) </span>
            </template>
          </v-select>
        </v-col>
      </template>
      <template v-else-if="isDate(filter)">
        <v-col cols="8" class="px-1">
          <v-menu transition="scale-transition" :return-value="filter.value" min-width="auto" :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="prettyDate(filter.value)"
                prepend-inner-icon="mdi-calendar"
                readonly
                dense
                :rules="RULES.DATE(filter)"
                :disabled="!filter.active"
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="filter.value" range no-title scrollable :disabled="!filter.active" />
          </v-menu>
        </v-col>
      </template>
      <template v-else-if="isTextField(filter)">
        <v-col cols="4" class="px-1">
          <v-select
            v-model="filter.operator"
            label="Оператор сравнения"
            :rules="isNumber(filter) ? RULES.NUMBER.MAIN_OPERATOR(filter) : RULES.STRING.MAIN_OPERATOR(filter)"
            :items="isString(filter) ? STRING_OPERATOR_ITEMS : NUMBER_OPERATOR_ITEMS"
            item-text="name"
            item-value="value"
            :disabled="!filter.active"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="4" class="px-1">
          <v-text-field
            v-model="filter.value"
            label="Значение"
            :disabled="!filter.active || !stringFilterNeedsValue(filter)"
            :type="isNumber(filter) ? 'number' : 'text'"
            :rules="isNumber(filter) ? RULES.NUMBER.MAIN_VALUE(filter) : RULES.STRING.MAIN_VALUE(filter)"
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col v-if="isNumber(filter) && rangeAvailable(filter)" cols="1" class="px-1">
          <v-switch v-model="filter.range.active" class="mt-0" append-icon="mdi-code-not-equal-variant" hide-details dense />
        </v-col>
        <v-col v-if="rangeOpen(filter)" cols="3" class="px-1 pt-3">
          <v-select
            v-model="filter.range.logic"
            label="Лог. оператор"
            :items="LOGIC_OPERATOR_ITEMS"
            :rules="RULES.NUMBER.LOGIC_OPERATOR(filter)"
            item-text="name"
            item-value="value"
            :disabled="!filter.active"
            dense
            hide-details
          >
          </v-select>
        </v-col>
        <v-col v-if="rangeOpen(filter)" cols="4" class="px-1 pt-3">
          <v-select
            v-model="filter.range.operator"
            label="Оператор сравнения"
            :items="NUMBER_OPERATOR_ITEMS"
            :rules="RULES.NUMBER.RANGE_OPERATOR(filter)"
            item-text="name"
            item-value="value"
            :disabled="!filter.active"
            dense
            hide-details
          >
          </v-select>
        </v-col>
        <v-col v-if="rangeOpen(filter)" cols="4" class="px-1 pt-3">
          <v-text-field v-model="filter.range.value" label="Значение" :disabled="!filter.active" type="number" :rules="RULES.NUMBER.RANGE_VALUE(filter)" dense hide-details></v-text-field>
        </v-col>
      </template>
    </v-row>
    <v-row class="mt-0 py-3" no-gutters>
      <v-col offset="0" offset-sm="6" cols="6" sm="3">
        <v-btn color="secondary" small @click.prevent="resetFilters"> Сбросить</v-btn>
      </v-col>
      <v-col cols="6" sm="3">
        <v-btn color="primary" small @click.prevent="updateFilters"> Применить</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  isEnum,
  isMultiple,
  isString,
  isNumber,
  isDate,
  isSelect,
  isTextField,
  rangeAvailable,
  rangeOpen,
  stringFilterNeedsValue,
  RULES,
  STRING_OPERATOR_ITEMS,
  NUMBER_OPERATOR_ITEMS,
  LOGIC_OPERATOR_ITEMS,
  rawFilterFactory,
  fetchFilterFactory,
} from '../_utils/filters';

export default {
  name: 'ModulFilterForm',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    moduleName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    filters: [],
    startDateMenu: false,
    endDateMenu: false,
    RULES,
    STRING_OPERATOR_ITEMS,
    NUMBER_OPERATOR_ITEMS,
    LOGIC_OPERATOR_ITEMS,
  }),
  async created() {
    this.filters = await Promise.all(
      this.columns.map(async (col) => {
        const filter = await rawFilterFactory(col);
        return Promise.resolve(filter);
      })
    );
  },
  methods: {
    isEnum,
    isMultiple,
    isString,
    isNumber,
    isDate,
    isSelect,
    isTextField,
    cons(e) {
      console.log(e);
    },
    rangeAvailable({ operator }) {
      return rangeAvailable(operator);
    },
    rangeOpen({ range, operator }) {
      return rangeOpen(range, operator);
    },
    stringFilterNeedsValue({ operator }) {
      return stringFilterNeedsValue(operator);
    },
    getFilterOptions({ options }) {
      // for getting task states assigned to task types
      const typeFilter = this.filters.find((f) => f.column === 'type');
      const isTypeFilterActive = typeFilter?.active;
      const typeValues = typeFilter?.value;
      const typeNot = typeFilter?.not;
      if (isTypeFilterActive && typeValues?.length > 0) {
        if (typeNot) {
          return options.filter((opt) => !typeValues.includes(opt.task_type));
        }
        return options.filter((opt) => typeValues.includes(opt.task_type));
      }
      return options || undefined;
    },
    setStatesDependingOnTypes() {
      const stateFilter = this.filters.find((f) => f.column === 'state');
      const availableStates = this.getFilterOptions(stateFilter).map((opt) => opt.task_type);
      stateFilter.values = stateFilter?.values?.filter((v) => availableStates.includes(v));
    },
    getFetchFilters() {
      return this.filters.map((f) => fetchFilterFactory(f)).filter((f) => !!f);
    },
    updateFilters() {
      if (this.$refs.form.validate()) {
        console.log(this.filters.map((f) => fetchFilterFactory(f)));
        this.$emit('update:filters', this.getFetchFilters());
      }
    },
    resetFilters() {
      this.$refs.form.reset();
      this.$emit('reset:filters');
      this.filters = this.columns.map((col) => {
        return rawFilterFactory(col);
      });
    },
    prettyDate(dates) {
      if (Array.isArray(dates) && dates.length > 0) {
        const _dates = [...dates];
        switch (_dates.length) {
          case 1: {
            const [year, month, day] = _dates[0].split('-');
            return `${day}.${month}.${year}`;
          }
          case 2: {
            _dates.sort();
            const [year_1, month_1, day_1] = _dates[0].split('-');
            const [year_2, month_2, day_2] = _dates[1].split('-');
            return `${day_1}.${month_1}.${year_1} - ${day_2}.${month_2}.${year_2}`;
          }
          default: {
            return '';
          }
        }
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  &::v-deep input {
    display: none;
  }
}
</style>
