import { isMultiple, isNumber, isSelect, isTextField } from './filterConditions';
import { LOGIC_OPERATOR_VALUES } from '../_const/filterConstants';
import { serverGet } from '@/api';

/*
 * rawFilterFactory - makes a filter that is lightweight and easy to render
 * gets object with {type, text (column header), value (column value), options (if enum or multiple)}
 * returns object
 *
 * */

const rawFilterFactory = async ({ data_type: type, text, value: column, options, values_method: valuesMethod }) => {
  const filter = {
    type, // тип фильтра
    text, // текст колонки
    column, // значение колонки
    active: false, // включен ли фильтр
    value: undefined, // основное значения фильтра
    ...(isSelect(type) && { options }), // Опции для селектов
    ...(isMultiple(type) && { not: false }), // включен ли реверс фильтра
    ...(isTextField(type) && { operator: undefined }), // оператор (больше/меньше/равно...)
    ...(isNumber(type) && {
      range: {
        // для фильтров типа number существует возможность задать диапазон,
        active: false, // включен ли диапазон
        value: undefined, // второе значение диапазона
        operator: undefined, // оператор (больше/меньше/равно...)
        logic: LOGIC_OPERATOR_VALUES.AND, // логика (и/или)
      },
    }),
  };
  if (valuesMethod) {
    filter.options = await serverGet(valuesMethod);
  }
  return filter;
};

export default rawFilterFactory;
