import server from '@/api/server';

async function init({ login, pass }) {
  return await server.post('/account/login_init', { pass, login });
}

async function refresh(phone) {
  return await server.post('/account/', { phone });
}

async function confirm({ smsid, code }) {
  return await server.post('/account/login', { smsid, code });
}

async function logout() {
  return await server.get('/account/logout');
}

export { init, confirm, refresh, logout };
