import {
  SET_ACTIVE_FLOW_ID,
  SET_ACTIVE_EDGE_ID,
  SET_SIDE_PANEL,
  SET_SIDE_PANEL_TYPE,
  SET_SIDE_PANEL_DATA_TYPE,
  SET_DRAWFLOW_WIDTH,
  SET_ACTIVE_NODE_ID,
  SET_NEED_TO_REDRAW,
  SET_REFRESH_FLOW_DIALOG,
} from '@/store/mutations';

const initState = {
  drawflowWidth: 0,
  activeFlowId: null,
  activeNodeId: null,
  activeEdgeId: null,
  sidePanelType: null, // ["data", "process", "publication"]
  sidePanelDataType: null, // ["node" || "edge"]
  sidePanelOpened: false,
  sidePanelData: {},
  needsToBeRedrawn: false,
  refreshFlowDialog: false,
};

export const flows = {
  namespaced: true,
  state: () => initState,
  getters: {
    moduleName: ({ activeFlowId }) => (activeFlowId ? `flows/flow_${activeFlowId}` : null),
    nodeModuleName: ({ activeFlowId, activeNodeId }) => {
      return activeFlowId && activeNodeId ? `flows/flow_${activeFlowId}/node_${activeNodeId}` : null;
    },
    edgeModuleName: ({ activeFlowId, activeEdgeId }) => {
      return activeFlowId && activeEdgeId ? `flows/flow_${activeFlowId}/edge_${activeEdgeId}` : null;
    },
  },
  mutations: {
    [SET_ACTIVE_FLOW_ID]: (state, payload) => (state.activeFlowId = payload),
    [SET_ACTIVE_EDGE_ID]: (state, payload) => (state.activeEdgeId = payload),
    [SET_ACTIVE_NODE_ID]: (state, payload) => (state.activeNodeId = payload),
    [SET_SIDE_PANEL]: (state, payload = false) => {
      state.sidePanelOpened = payload;
    },
    [SET_SIDE_PANEL_TYPE]: (state, payload) => {
      state.sidePanelType = payload;
    }, // ["data" || "process" || "publication"]
    [SET_SIDE_PANEL_DATA_TYPE]: (state, payload) => {
      state.sidePanelDataType = payload;
    }, // ["node" || "edge"]
    [SET_DRAWFLOW_WIDTH]: (state, payload) => {
      state.drawflowWidth = payload;
    },
    [SET_NEED_TO_REDRAW]: (state, payload) => {
      state.needsToBeRedrawn = payload;
    },
    [SET_REFRESH_FLOW_DIALOG]: (state, payload) => {
      state.refreshFlowDialog = payload;
    },
  },
};
