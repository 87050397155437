export function clearPhone(phone) {
  return phone.replace(/\D/g, '');
}

export function createResetMutation(initState) {
  return function reset(state) {
    const initialState = initState();
    Object.keys(initialState).forEach((key) => (state[key] = initialState[key]));
  };
}

// let makeCurrency = (val, curr = '₽') => `${val} ${curr}`;
//
// const patternEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/i;
//
// function formatPhone(phone) {
//   const phoneDigits = phone.replace(/\D/g, '');
//   if (phoneDigits.length !== 11) return phone;
//   return phoneDigits.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
// }
//
// function formatDate(str) {
//   if (!str) return null;
//
//   const [year, month, day] = str.split('-');
//   return `${day}.${month}.${year}`;
// }
//
// export { makeCurrency, patternEmail, formatPhone, formatDate };
