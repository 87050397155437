import * as uuid from 'uuid';

class Guid {
  static get newid() {
    return uuid.v4().toUpperCase();
  }

  static get empty() {
    return uuid.NIL;
  }

  static equals(guid, otherGuid) {
    return guid && otherGuid && typeof guid === 'string' && typeof otherGuid === 'string' && guid.toUpperCase() === otherGuid.toUpperCase();
  }

  static isValid(guid) {
    return guid && typeof guid === 'string' && uuid.validate(guid);
  }
}

export default Guid;
