<template>
  <v-card>
    <v-card-text>
      <v-form ref="form" class="mt-12">
        <v-row dense>
          <v-col cols="5">
            <v-text-field v-model="userID" readonly label="Идентификатор пользователя" dense />
          </v-col>
          <v-col cols="5">
            <v-text-field v-model="userName" readonly label="Имя" dense />
          </v-col>
          <v-col cols="5">
            <v-text-field v-model="userPhone" readonly label="Номер" dense />
          </v-col>
          <v-col cols="5">
            <v-text-field v-model="userEmail" readonly label="Электронная почта" dense />
          </v-col>
          <v-col cols="5">
            <v-text-field v-model="userReg" readonly label="Дата регистрации" dense />
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="userStatus" readonly label="Список ролей" dense />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="error" disabled @click="deleteEntity">Удалить</v-btn>
      <v-btn class="primary" disabled @click="deleteEntity">Подтвердить регистрацию</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import { getUserInfo } from '@/api';

export default {
  name: 'UserForm',
  data: () => ({
    userID: '',
    userName: '',
    userPhone: '',
    userEmail: '',
    userReg: '',
    userStatus: '',
  }),
  computed: {
    activeEntityId() {
      return this.$route.params.entityId;
    },
  },
  watch: {
    activeEntityId: {
      async handler(newVal) {
        const info = await getUserInfo({ id: newVal });

        this.userID = info.id;
        this.userName = info.name;
        this.userPhone = info.login;
        this.userEmail = info.email;
        this.userReg = info.dadd;
        this.userStatus = info.status.join(', ');

        this.setTabName({
          fullPath: this.$route.fullPath,
          newName: info.name,
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('tabs', ['setTabName', 'removeTab']),
    async deleteEntity() {
      this.removeTab({ fullPath: this.$route.fullPath });
    },
  },
};
</script>

<style scoped lang="scss"></style>
