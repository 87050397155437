<template>
  <component :is="fieldModelComponent" :model-id="modelId" :field-name="fieldName" @field-changed="fieldChanged($event)" />
</template>

<script>
import MFInput from '@/components/modelForm/modelField/MFInput.vue';
import MFSelect from '@/components/modelForm/modelField/MFSelect.vue';
import getModuleName from '@/components/modelForm/utils/getModuleName';

export default {
  name: 'ModelField',
  components: {
    MFInput,
    MFSelect,
  },
  props: {
    modelId: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
  },
  computed: {
    moduleName() {
      return getModuleName(this.modelId);
    },
    fieldType() {
      return this.$store.state[this.moduleName]?.model[this.fieldName]?.data?.type || 'input';
    },
    fieldModelComponent() {
      switch (this.fieldType) {
        case 'selection': {
          return 'MFSelect';
        }
        case 'input': {
          return 'MFInput';
        }
        default: {
          return 'MFInput';
        }
      }
    },
  },
  methods: {
    fieldChanged(ev) {
      this.$emit('field-changed', { fieldName: this.fieldName, value: ev });
    },
  },
};
</script>

<style scoped lang="scss"></style>
