// flows mutations

export const SET_ACTIVE_FLOW_ID = 'setActiveFlowId';
export const SET_ACTIVE_NODE_ID = 'setActiveNodeId';
export const SET_ACTIVE_EDGE_ID = 'setActiveEdgeId';
export const SET_SIDE_PANEL = 'setSidePanel';
export const SET_SIDE_PANEL_TYPE = 'setSidePanelType';
export const SET_SIDE_PANEL_DATA_TYPE = 'setSidePanelDataType';
export const SET_DRAWFLOW_WIDTH = 'setDrawflowWidth';
export const SET_NEED_TO_REDRAW = 'setNeedToRedraw';
export const SET_REFRESH_FLOW_DIALOG = 'setRefreshFlowDialog';

// flow mutations

export const SET_NODE_IDS = 'setNodeIds';
export const SET_EDGE_IDS = 'setEdgeIds';
export const SET_ROOT_NODE_NAME = 'setRootNodeName';
export const SET_FLOW_MOUNTED = 'setFlowMounted';
export const TOGGLE_LOCK = 'toggleLock';
export const SET_ZOOM = 'setZoom';
export const SET_CANVAS_POSITION = 'setCanvasPosition';
export const SET_AVAILABLE_NEW_TASK_TYPES = 'setAvailableNewTaskTypes';
export const REGISTER_NODE = 'registerNode';
export const REGISTER_EDGE = 'registerEdge';
export const SET_LAST_NODE_MOVE = 'setLastNodeMove';
export const SET_TASK_TYPE_SEARCH_QUERY = 'setTaskTypeSearchQuery';
export const SET_NEEDS_REFRESH = 'setNeedsRefresh';

// node mutations

export const SET_NODE_INIT = 'setNodeInit';
export const SET_IS_TASK_EXE = 'setIsTaskExe';
export const SET_NODE_NEW_COORDS = 'setNodeNewCoords';

// edge mutations

export const SET_EDGE_INIT = 'setEdgeInit';

// grid mutations

export const SET_GRID = 'setGrid';

// stepBuilder mutations

export const SET_MODEL_TYPES = 'setModelTypes';
export const SET_STEP_SETTINGS = 'setStepSettings';
