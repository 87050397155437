<template>
  <SBModelForm v-if="isModel" @close="$emit('close')" />
  <PrototypeForm v-else-if="isPrototype" @close="$emit('close')" />
  <StepForm v-else-if="isStep" @close="$emit('close')" />
  <UserForm v-else-if="isUser" @close="$emit('close')" />
</template>

<script>
import SBModelForm from '@/components/stepBuilder/entityForms/SBModelForm';
import PrototypeForm from '@/components/stepBuilder/entityForms/PrototypeForm';
import StepForm from '@/components/stepBuilder/entityForms/StepForm';
import UserForm from '@/components/stepBuilder/entityForms/UserForm';

export default {
  name: 'EntityBuilder',
  components: { StepForm, PrototypeForm, SBModelForm, UserForm },
  props: {
    mode: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    _mode() {
      return this.mode || this.$route.params.sbType;
    },
    isModel() {
      return this._mode === 'schemas';
    },
    isPrototype() {
      return this._mode === 'prototypes';
    },
    isStep() {
      return this._mode === 'steps';
    },
    isUser() {
      return this._mode === 'users';
    },
  },
};
</script>

<style scoped lang="scss">
.sb-dialog {
  &__button {
    position: fixed;
    bottom: 4rem;
    right: 5rem;
  }
}
</style>
