<template>
  <v-navigation-drawer v-model="opened" :temporary="false" :right="right" :width="width || '40%'" clipped app class="side-panel">
    <v-toolbar>
      <div :class="{ 'flex-row-reverse': !right, 'pl-4': !right, 'pr-4': right }" class="d-flex toolbar__content">
        <v-btn
          v-for="(burger, index) in backburgers"
          :key="burger.text"
          class="toolbar__button toolbar__button_open"
          :class="{ toolbar__button_left: right, toolbar__button_right: !right }"
          :style="{ top: 230 * index + 'px !important' }"
          @click="toggle(burger)"
        >
          {{ burger.text }}
        </v-btn>
        <v-spacer />
        <v-toolbar-title>
          <h2 class="text-h6">{{ currentObjectTitle }}</h2>
        </v-toolbar-title>
        <v-spacer />
      </div>
    </v-toolbar>
    <slot />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SidePanel',
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String || Number,
      default: undefined,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    backburgers: {
      type: Array,
      default: null,
    },
    openButtonText: {
      type: String,
      default: '',
    },
    currentObject: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentObjectTitle() {
      return this.backburgers?.find((b) => b.object === this.currentObject)?.text;
    },
  },
  methods: {
    toggle(burger) {
      this.$emit('toggle', burger);
    },
  },
};
</script>

<style scoped lang="scss">
.side-panel {
  visibility: visible !important;
  overflow: visible;
  &::v-deep .v-navigation-drawer__content {
    overflow: visible;
  }
}
.toolbar {
  &__content {
    width: 100%;
    justify-content: space-between;
    position: relative;
    overflow: visible;
  }
  &__button {
    &_open {
      position: absolute;
      height: 200px !important;
      width: 36px;
      min-width: 36px !important;
      ::v-deep .v-btn__content {
        transform: rotateZ(90deg);
      }
    }
    &_left {
      left: -52px;
    }
    &_right {
      right: -52px;
    }
  }
  &__icon {
    &_reversed {
      transform: scaleX(-1);
    }
  }
}
</style>
