<template>
  <v-card>
    <v-card-title>
      {{ cardTitle }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="5">
            <v-text-field v-model="stepName" label="Название степа" placeholder="Название степа" :rules="[rules.required]" dense />
          </v-col>
          <v-col cols="7">
            <v-text-field v-model="stepDescription" label="Описание степа" placeholder="Описание..." dense />
          </v-col>
          <v-col cols="3">
            <v-select v-model="prototypeId" label="Прототип" :items="prototypeOptions" item-value="id" item-text="name" :rules="[rules.required]" dense />
          </v-col>
          <v-col cols="3">
            <v-select v-model="parentId" label="Родительский степ" :items="stepOptions" item-value="id" item-text="name" dense />
          </v-col>
          <v-col cols="6">
            <v-select v-model="forbiddenConnections" label="Запрещенные соединения" :items="stepOptions" item-value="id" item-text="name" multiple dense />
          </v-col>
          <v-col cols="3">
            <v-select v-model="stepSync" label="Доступен в синхронных картах" :items="yesNoOptions" item-value="value" item-text="label" :rules="[rules.required]" dense />
          </v-col>
          <v-col cols="3">
            <v-select v-model="stepAsync" label="Доступен в асинхронных картах" :items="yesNoOptions" item-value="value" item-text="label" :rules="[rules.required]" dense />
          </v-col>
          <v-col cols="3">
            <v-select v-model="hasTask" label="Требуется создание таска" :items="yesNoOptions" item-value="value" item-text="label" :rules="[rules.required]" dense />
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="stepGroup" label="Название группы степов" placeholder="Группа" dense />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="defaultStepProcedure" label="Процедура инициализации степа" placeholder="Процедура" dense />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="defaultServiceName" label="Сервис по умолчанию" placeholder="Сервис (БД)" dense />
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="defaultExecution" label="Действие степа по умолчанию" placeholder="Узел ms_api или процедура БД" dense />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="error" @click="deleteEntity">Удалить</v-btn>
      <v-btn class="primary" @click="submitEntity">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { GET_STEP_SETTINGS } from '@/store/actions';

import { getSBSteps, getSBPrototypes, buildSBStep, getSBEntity, deleteSBEntity } from '@/api';
import { SBStepFactory } from '@/utils/SBStepFactory';
import { ENTITY_MAP, SBDialogTitles } from '@/utils/constants';
import { required } from '@/utils/rules';

export default {
  name: 'StepForm',
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    oldEntityId: undefined,
    stepName: '',
    stepDescription: '',
    stepSync: true,
    stepAsync: true,
    defaultStepProcedure: '',
    defaultServiceName: '',
    defaultExecution: '',
    stepGroup: 'Узконаправленные',
    hasTask: false,
    forbiddenConnections: [],
    prototypeId: undefined,
    parentId: undefined,
    stepSettingsFields: [],
    stepOptions: [],
    prototypeOptions: [],
    rules: {
      required: required('Обязательное поле'),
    },
    yesNoOptions: [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ],
  }),
  computed: {
    ...mapState('stepBuilder', ['stepSettings']),
    ...mapGetters('stepBuilder', ['stepSettingsLoaded']),
    activeEntityId() {
      return this.$route.params.entityId;
    },
    cardTitle() {
      return this.stepName || SBDialogTitles.steps;
    },
    isNew() {
      return this.activeEntityId === 'new';
    },
  },
  watch: {
    activeEntityId: {
      async handler(newVal) {
        if (!newVal || newVal === this.oldEntityID) {
          return;
        }

        this.oldEntityID = newVal;
        this.stepOptions = await getSBSteps();
        this.prototypeOptions = await getSBPrototypes();
        this.stepSettingsFields = [...this.stepSettings];
        await this.initEntity(newVal);

        this.setTabName({
          fullPath: this.$route.fullPath,
          newName: `Степ - ${this.cardTitle || 'Безымянный'}`,
        });
      },
      immediate: true,
    },
  },
  async created() {
    if (!this.stepSettingsLoaded) {
      await this[GET_STEP_SETTINGS]();
    }
  },
  methods: {
    ...mapActions('stepBuilder', [GET_STEP_SETTINGS]),
    ...mapMutations('tabs', ['setTabName', 'removeTab']),
    getFieldRules(required) {
      if (required) return [this.rules.required];
      return [];
    },
    async submitEntity() {
      if (this.$refs.form.validate()) {
        const payload = SBStepFactory({
          name: this.stepName,
          description: this.stepDescription,
          prototypeId: this.prototypeId,
          parentId: this.parentId,
          forbiddenConnections: this.forbiddenConnections,
          settings: this.stepSettingsFields,
          sync: this.stepSync,
          async: this.stepAsync,
          defaultStepProcedure: this.defaultStepProcedure,
          defaultServiceName: this.defaultServiceName,
          defaultExecution: this.defaultExecution,
          groupName: this.stepGroup,
          hasTask: this.hasTask,
          updating: this.activeEntityId !== 'new',
        });
        const result = await buildSBStep(payload);

        // Change current route with new id
        if (this.activeEntityId === 'new') {
          this.removeTab({ fullPath: this.$route.fullPath });
          this.$router.push(`/${ENTITY_MAP.sb_steps.baseRoute}/${result.id}`);
        }
      }
    },
    async deleteEntity() {
      await deleteSBEntity({ type: 'step', id: this.activeEntityId });
      this.removeTab({ fullPath: this.$route.fullPath });
    },
    async initEntity(id) {
      if (id && id !== 'new') {
        const step = await getSBEntity({ type: 'step', id });
        this.stepName = step.name;
        this.stepDescription = step.description;
        this.prototypeId = step.prototypeid;
        this.parentId = step.parentid;
        this.forbiddenConnections = step.forbidden_connections;
        this.stepSync = step.sync;
        this.stepAsync = step.async;
        this.defaultStepProcedure = step.default_step_procedure;
        this.defaultServiceName = step.default_service_name;
        this.defaultExecution = step.default_execution;
        this.stepGroup = step.group_name;
        this.hasTask = step.has_task;
        step.settings?.forEach((el) => {
          const oldVal = el.value;
          const fieldName = el.field;
          const localField = this.stepSettingsFields.find((field) => field.name === fieldName);
          if (localField) localField.value = oldVal;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
