<template>
  <SidePanel width="20%" :opened="opened" current-object="steps" :backburgers="[{ text: 'Доступные узлы', object: 'steps' }]" @toggle="toggle">
    <template v-if="visible" #default>
      <v-text-field v-model="searchQuery" prepend-icon="mdi-magnify" placeholder="Поиск" class="pa-2"></v-text-field>
      <div ref="nodeList" class="aside-browser__node-list pa-2">
        <template v-if="searchIsOn">
          <v-card v-for="node in foundTaskTypes" :key="node.id" :draggable="!locked" class="my-1 aside-browser__node" @dragstart="dragStart($event, node)">
            <v-card-title>{{ node.type }}</v-card-title>
            <v-card-text>{{ node.description }}</v-card-text>
          </v-card>
        </template>
        <v-expansion-panels v-else accordion multiple>
          <v-expansion-panel v-for="(group, key) of groups" :key="key">
            <v-expansion-panel-header>
              {{ key }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card v-for="node in group" :key="node.id" :draggable="!locked" class="my-1 aside-browser__node" @dragstart="dragStart($event, node)">
                <v-card-title>{{ node.type }}</v-card-title>
                <v-card-text>{{ node.description }}</v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </SidePanel>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_TASK_TYPE_SEARCH_QUERY } from '@/store/mutations';
import SidePanel from '@/components/common/SidePanel';

export default {
  name: 'AsideBrowser',
  components: {
    SidePanel,
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    ...mapGetters('flows', ['moduleName']),
    availableNewTaskTypes() {
      return this.$store.state[this.moduleName]?.availableNewTaskTypes;
    },
    locked() {
      return this.$store.state[this.moduleName]?.locked;
    },
    visible() {
      return !!this.availableNewTaskTypes?.length;
    },
    searchIsOn() {
      return !!this.searchQuery;
    },
    foundTaskTypes() {
      return this.$store.getters[`${this.moduleName}/foundTaskTypes`];
    },
    searchQuery: {
      get() {
        return this.$store.state[this.moduleName]?.searchTaskTypeQuery;
      },
      set(newVal) {
        this.$store.commit(`${this.moduleName}/${SET_TASK_TYPE_SEARCH_QUERY}`, newVal);
      },
    },
    groups() {
      return this.$store.getters[`${this.moduleName}/taskTypeGroups`];
    },
  },
  watch: {
    availableNewTaskTypes(newVal, oldVal) {
      if (oldVal) this.$refs.nodeList.scrollTo(0, 0);
    },
  },
  methods: {
    dragStart(e, nodeData) {
      e.dataTransfer.setData('text/plain', JSON.stringify(nodeData));
    },
    toggle() {
      this.opened = !this.opened;
    },
  },
};
</script>

<style scoped lang="scss">
.aside-browser {
  &__node-list {
    max-height: $panels-height;
    overflow: auto;
  }

  &__node {
    cursor: grab;

    &[draggable='false'] {
      cursor: no-drop;
    }

    &:active {
      cursor: grabbing;
    }
  }
}
</style>
