import { getUserInfo } from '@/api';
import { createResetMutation } from '@/utils/helpers';

const initState = {
  errdesc: '',
  alerts: [],
  uid: 0,
  loading: 0,
  temporaryErrors: [],
  errNodes: {},
  processInfo: {},
  userInfo: {},
};

export const common = {
  namespaced: true,
  state: () => initState,
  getters: {
    errdesc: (state) => state.errdesc,
    alerts: (state) => state.alerts,
    loading: (state) => Boolean(state.loading),
    userName: (state) => state.userInfo.name || 'Пользователь',
    userIsAdmin: (state) => !!(state.userInfo.status?.findIndex((s) => s === 'admin') !== -1),
  },
  mutations: {
    setErrdesc: (state, value) => (state.errdesc = value),
    addAlert(state, { type, msg }) {
      state.uid++;
      state.alerts.push({ type, msg, id: state.uid });
    },
    removeAlert(state, id) {
      let i = state.alerts.filter((el) => el.uid === id);
      state.alerts.splice(i, 1);
    },
    startLoading(state) {
      state.loading = 1; // state.loading + 1;
    },
    stopLoading(state) {
      state.loading = 0; // state.loading - 1;
    },
    setProcessInfo(state, payload) {
      state.processInfo = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },

    // метод для сброса стейта
    reset: createResetMutation(initState),
  },
  actions: {
    addAlert({ commit }, { type, msg }) {
      commit('addAlert', { type, msg });

      // автоудаление через 3 сек
      setTimeout(() => {
        commit('removeAlert');
      }, 3000);
    },

    setTemporaryErrors({ state }, payload) {
      state.temporaryErrors = payload;
      setTimeout(() => {
        state.temporaryErrors = [];
      }, 3000);
    },

    setErrNodes({ state }, payload) {
      state.errNodes = payload;
    },

    async updateUserInfo({ commit }) {
      const result = await getUserInfo();
      commit('setUserInfo', result);
    },
  },
};
