export const SBPrototypeFactory = ({
  name,
  inputId,
  outputId,
  // isSecure,
  // hashType,
  // keyInstructions,
  inputFields,
  outputFields,
}) => {
  try {
    const prototype = /* isSecure
      ? { secure_test: { hash: hashType, key_instruction: keyInstructions } }
      : */ { simple_test: null };
    const input = {};
    inputFields.forEach((field) => {
      const val = field.value;
      if (val !== undefined) input[field.field] = val;
    });
    const output = {};
    outputFields.forEach((field) => {
      const val = field.value;
      if (val !== undefined) output[field.field] = val;
    });
    const hasInput = !!Object.keys(input).length;
    const hasOutput = !!Object.keys(output).length;
    const hasPredefined = hasInput || hasOutput;
    return {
      name,
      input_schema: inputId,
      output_schema: outputId,
      prototype,
      ...(hasPredefined && { predefined_data: { ...(hasInput && { input }), ...(hasOutput && { output }) } }),
    };
  } catch (e) {
    console.log(e);
    return {};
  }
};
