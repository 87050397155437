<template>
  <v-form ref="form" :key="modelId" :disabled="disabled">
    <ModelField v-for="(field, fieldName) of storedModel" :key="fieldName" :model-id="modelId" :field-name="fieldName" @field-changed="fieldChanged($event)" />
  </v-form>
</template>

<script>
import ModelField from '@/components/modelForm/modelField/ModelField';
import getModuleName from '@/components/modelForm/utils/getModuleName';
import modelForm from '@/store/modules/modelForm';

export default {
  name: 'ModelForm',
  components: { ModelField },
  props: {
    modelName: {
      type: String,
      default: '',
    },
    modelId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noinit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    moduleName() {
      return getModuleName(this.modelId);
    },
    storedModel() {
      return this.$store.state[this.moduleName]?.model || {};
    },
  },
  async created() {
    if (!this.noinit && !this.$store.hasModule(this.moduleName)) {
      const module = await modelForm(this.modelName);
      this.$store.registerModule(this.moduleName, module);
    }
  },
  methods: {
    fieldChanged(ev) {
      this.$emit('field-changed', ev);
      this.$store.dispatch(`${this.moduleName}/invalidateFields`, ev.fieldName);
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang="scss"></style>
