<template>
  <div class="mt-4">
    <div class="d-flex justify-space-between align-center">
      <ModulFilter :columns="headers" @update:filters="updateFilters" @reset:filters="resetFilters" />
    </div>
    <v-data-table
      :headers="visibleColumns"
      :items="items"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="viewData.total_found || 0"
      :loading="loading"
      loading-text="Загружаем данные"
      no-data-text="Ничего не найдено"
      class="grid"
      @click:row="onRowClick"
    >
      <template #top>
        <v-text-field v-model="search" placeholder="Поиск" prepend-inner-icon="mdi-magnify" />
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template #item.grid_subtype="{ item }">
        <v-icon>{{ getTypeIcon(item.grid_subtype) }}</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_GRID_DATA } from '@/store/actions';
import ModulFilter from '@/components/modul-grid/components/modul-filters';
import { ENTITY_MAP } from '@/utils/constants';

export default {
  name: 'GridView',
  components: { ModulFilter },
  data: () => ({
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 20,
      multiSort: false,
      sortBy: [],
      sortDesc: [],
    },
    footerProps: {
      'items-per-page-options': [10, 20, 50, 100],
      'items-per-page-all-text': 'Все',
      'items-per-page-text': 'Показать по',
      'show-current-page': true,
      'show-first-last-page': true,
      pageText: '{0}-{1} из {2}',
    },
    search: '',
    filters: [],
  }),
  computed: {
    ...mapState('grid', ['gridData']),
    viewData() {
      return this.gridData || { columns: [], data: [] };
    },
    headers() {
      return this.viewData.columns || [];
    },
    visibleColumns() {
      return this.headers.filter((col) => !col.hidden);
    },
    items() {
      return this.viewData.data || [];
    },
    fetchOptions() {
      return {
        params: {
          ...(this.search && { search: this.search }),
          ...(this.options.page && { page: this.options.page }),
          ...(this.options.itemsPerPage && { page_size: this.options.itemsPerPage }),
          ...(this.options.sortBy[0] && {
            order: [{ field: this.options.sortBy[0], dir: this.options.sortDesc[0] ? 'desc' : 'asc' }],
          }),
          ...(this.filters.length && { filter: this.filters }),
        },
      };
    },
  },
  watch: {
    fetchOptions: {
      handler: async function (newVal) {
        if (newVal) await this.fetchData();
      },
      deep: true,
      immediate: true,
    },
    search() {
      this.options.page = 1;
    },
  },
  methods: {
    ...mapActions('grid', [GET_GRID_DATA]),
    async fetchData() {
      this.loading = true;
      await this[GET_GRID_DATA](this.fetchOptions);
      this.loading = false;
    },
    updateFilters(filters) {
      this.filters = filters;
    },
    resetFilters() {
      this.updateFilters([]);
    },
    onRowClick(row) {
      const type = ENTITY_MAP[row.grid_subtype];
      if (type) {
        this.$router.push(`/${type.baseRoute}/${row.id}`);
      }
    },
    getTypeIcon(type) {
      return ENTITY_MAP[type]?.icon ?? type;
    },
  },
};
</script>

<style scoped lang="scss">
.grid {
  cursor: pointer;
}
</style>
