import { GET_MAP, PUBLISH_FLOW, SAVE } from '@/store/actions';
import {
  SET_NODE_IDS,
  SET_EDGE_IDS,
  SET_FLOW_MOUNTED,
  TOGGLE_LOCK,
  SET_ZOOM,
  SET_CANVAS_POSITION,
  REGISTER_NODE,
  REGISTER_EDGE,
  SET_AVAILABLE_NEW_TASK_TYPES,
  SET_LAST_NODE_MOVE,
  SET_TASK_TYPE_SEARCH_QUERY,
  SET_NEEDS_REFRESH,
  SET_ROOT_NODE_NAME,
} from '@/store/mutations';
import { getMap, getTypes, publishFlow, saveMap } from '@/api';
import store from '@/store';
import Node from '@/store/modules/node';
import Edge from '@/store/modules/edge';

const registerEntityModule = (flowId, entityData, node = true) => {
  let entityModuleName;
  let entityClass;
  if (node) {
    entityModuleName = `flows/flow_${flowId}/node_${entityData.id}`;
    entityClass = Node;
  } else {
    entityModuleName = `flows/flow_${flowId}/edge_${entityData.id}`;
    entityClass = Edge;
  }
  if (store.hasModule(entityModuleName)) {
    store.unregisterModule(entityModuleName);
  }
  store.registerModule(entityModuleName, new entityClass(entityData, entityModuleName)); // FIXME: May work slower
};

const registerNodeModule = (flowId, nodeData) => registerEntityModule(flowId, nodeData, true);
const registerEdgeModule = (flowId, edgeData) => registerEntityModule(flowId, edgeData, false);

const initState = (flowId) => {
  return {
    id: flowId,
    nodeIds: [],
    edgeIds: [],
    availableNewTaskTypes: [],
    mounted: false,
    needsRefresh: false,
    locked: true,
    zoom: 1,
    canvasPosition: { x: 0, y: 0 },
    lastNodeMove: null,
    searchTaskTypeQuery: '',
    rootNodeName: 'Безымянный',
  };
};

export default class Flow {
  constructor(flowId) {
    this.namespaced = true;
    this.state = initState(flowId);
    this.getters = {
      mapLoaded: ({ nodeIds, edgeIds }) => nodeIds.length > 0 && edgeIds.length > 0,
      taskTypeGroups: ({ availableNewTaskTypes }) => {
        const groups = {};
        if (availableNewTaskTypes?.length) {
          availableNewTaskTypes.forEach((taskType) => {
            const groupKey = taskType.group_name.replaceAll('\\', '/');
            if (groups[groupKey]) {
              groups[groupKey].push(taskType);
            } else {
              groups[groupKey] = [taskType];
            }
          });
        }
        return groups;
      },
      searchOptimizedTaskTypes: ({ availableNewTaskTypes }) => {
        return availableNewTaskTypes.map(({ type, description }) => {
          return {
            type,
            searchString: `${type.toLowerCase()} ${description.toLowerCase()}`,
          };
        });
      },
      normalizedTaskTypeSearchQuery: ({ searchTaskTypeQuery }) => {
        if (searchTaskTypeQuery) {
          return searchTaskTypeQuery.toLowerCase();
        }
        return '';
      },
      foundTaskTypes: ({ availableNewTaskTypes }, { searchOptimizedTaskTypes, normalizedTaskTypeSearchQuery }) => {
        if (normalizedTaskTypeSearchQuery) {
          const ids = searchOptimizedTaskTypes.filter((optimizedType) => optimizedType.searchString.includes(normalizedTaskTypeSearchQuery)).map(({ type }) => type);
          return availableNewTaskTypes.filter(({ type }) => ids.includes(type));
        }
        return availableNewTaskTypes;
      },
    };
    this.mutations = {
      [SET_NODE_IDS]: (state, payload) => (state.nodeIds = payload),
      [SET_EDGE_IDS]: (state, payload) => (state.edgeIds = payload),
      [SET_FLOW_MOUNTED]: (state) => (state.mounted = true),
      [TOGGLE_LOCK]: (state, payload) => {
        if (!payload) {
          state.locked = !state.locked;
        } else {
          state.locked = !!payload;
        }
      },
      [SET_ZOOM]: (state, payload) => (state.zoom = payload),
      [SET_CANVAS_POSITION]: (state, payload) => (state.canvasPosition = payload), // x, y
      [SET_AVAILABLE_NEW_TASK_TYPES]: (state, payload) => (state.availableNewTaskTypes = payload),
      [REGISTER_NODE]: (state, payload) => {
        state.nodeIds.push(payload.id);
        registerNodeModule(state.id, payload);
      },
      [REGISTER_EDGE]: (state, payload) => {
        state.edgeIds.push(payload.id);
        registerEdgeModule(state.id, payload);
      },
      [SET_LAST_NODE_MOVE]: (state, payload) => {
        // { id, x, y }
        state.lastNodeMove = payload;
      },
      [SET_TASK_TYPE_SEARCH_QUERY]: (state, payload) => {
        state.searchTaskTypeQuery = payload;
      },
      [SET_NEEDS_REFRESH]: (state, payload) => {
        state.needsRefresh = payload;
      },
      [SET_ROOT_NODE_NAME]: (state, payload) => {
        state.rootNodeName = payload;
      },
    };
    this.actions = {
      [GET_MAP]: async ({ commit, state: { id } }, rebuild = false) => {
        const map = await getMap({
          flowid: id,
          rebuild,
          map_width: 2000,
          map_height: 2000,
        });
        let mapIsSync = false;
        let nodeIds = [];
        let edgeIds = [];
        let rootNodeName = 'No name';
        if (map) {
          if (map.nodes) {
            nodeIds = map.nodes.map((node) => node.id);
            const rootNode = map.nodes.find((node) => node.type === 'root');
            mapIsSync = !!rootNode?.data.is_sync;
            rootNodeName = rootNode?.data?.name || 'No name';
            map.nodes.forEach((nodeData) => registerNodeModule(id, nodeData));
          }
          if (map.edges) {
            edgeIds = map.edges.map((edge) => edge.id);
            map.edges.forEach((edgeData) => registerEdgeModule(id, edgeData));
          }
        }
        commit(SET_NODE_IDS, nodeIds);
        commit(SET_EDGE_IDS, edgeIds);
        commit(SET_ROOT_NODE_NAME, rootNodeName);

        const taskTypes = await getTypes(id);
        const availableNewTaskTypes = taskTypes.filter((taskType) => {
          return mapIsSync ? !!taskType.sync : !!taskType.async;
        });
        commit(SET_AVAILABLE_NEW_TASK_TYPES, availableNewTaskTypes);
      },
      [SAVE]: async ({ state: { id, nodeIds, edgeIds } }) => {
        const data = { flowid: id, map: { nodes: [], edges: [] } };
        const nodes = nodeIds.map((nodeId) => {
          const modelFormNode = `modelForm_flows/flow_${id}/node_${nodeId}`;
          const nodeData = store.state[`flows/flow_${id}/node_${nodeId}`];
          const changedNodeData = store.getters[`${modelFormNode}/step_model/data`] || store.getters[`${modelFormNode}/root_model/data`];
          const changedTaskData = !nodeData.is_task_exe ? store.getters[`${modelFormNode}/task_model/data`] : store.getters[`${modelFormNode}/task_exe_model/data`];
          const changedErrorHandlerData = store.getters[`${modelFormNode}/error_handler_model/data`];

          const oid = nodeData.oid || null;
          const taskid = nodeData.task_id || null;
          return {
            id: nodeData.id,
            X: nodeData.X,
            Y: nodeData.Y,
            type: nodeData.type,
            data: changedNodeData || nodeData.data,
            task_data: changedTaskData || nodeData.task_data,
            error_handler_data: changedErrorHandlerData || nodeData.error_handler_data,
            oid,
            taskid,
            is_task_exe: nodeData.is_task_exe,
          };
        });
        const edges = edgeIds.map((edgeId) => {
          const edgeData = store.state[`flows/flow_${id}/edge_${edgeId}`];
          const changedEdgeData = store.getters[`modelForm_flows/flow_${id}/edge_${edgeId}/route_model/data`];
          const oid = edgeData.oid || null;
          return {
            id: edgeId,
            source_node: edgeData.source_node,
            target_node: edgeData.target_node,
            type: 'route',
            data: changedEdgeData || edgeData.data,
            oid,
          };
        });
        data.map.nodes = nodes;
        data.map.edges = edges;
        await saveMap(data);
      },
      [PUBLISH_FLOW]: async ({ state: { id }, rootGetters }) => {
        const data = rootGetters['modelForm_flow_publication/data'];
        await publishFlow({ flowid: id, ...data });
      },
    };
  }
}
