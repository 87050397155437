import { INIT_EDGE } from '@/store/actions';
import { SET_EDGE_INIT } from '@/store/mutations';
import store from '@/store';
import modelForm from '@/store/modules/modelForm';

export default class Edge {
  constructor({ id, name, source_node, target_node, data, oid, type = 'default' }, entityModuleName) {
    this.namespaced = true;
    this.state = { id, name, source_node, target_node, oid, data, type, edgeInit: false, entityModuleName };
    this.mutations = {
      [SET_EDGE_INIT]: (state) => (state.edgeInit = true),
    };
    this.actions = {
      [INIT_EDGE]: async ({ commit, state: { data, entityModuleName, edgeInit } }) => {
        if (edgeInit) {
          return;
        }
        const loadedModule = await modelForm('route', data);
        const routeModuleFormName = `modelForm_${entityModuleName}/route_model`;
        store.registerModule(routeModuleFormName, loadedModule);
        commit(SET_EDGE_INIT);
      },
    };
  }
}
