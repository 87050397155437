import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import * as modules from './modules';
import * as api from '@/api';
import router from '@/router';

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    reset({ commit }) {
      Object.keys(modules).forEach((moduleName) => commit(`${moduleName}/reset`));
    },
  },
  modules,
});

function requestHandler(config) {
  store.commit('common/startLoading');
  return config;
}

function basicResponseHandler(resp) {
  store.commit('common/stopLoading');

  const data = resp?.data;

  if (data) {
    // прокидываем нормальный ответ
    if (data.status === 'ok') {
      return data.response || null;
    }

    // обработка ошибок
    if (data.status === 'err') {
      store.commit('common/setErrdesc', data.errdesc);
      store.dispatch('common/addAlert', { msg: data.errdesc, type: 'error' });
      if (data.info) {
        store.dispatch('common/setErrNodes', data.info);
      }
      return null;
    }

    store.dispatch('common/addAlert', { msg: 'Неожиданный ответ сервера', type: 'error' });
  }

  return null;
}

function errorHandler(e) {
  store.commit('common/stopLoading');

  if (e.response) {
    if (e.response.data?.info) {
      store.dispatch('common/setErrNodes', e.response.data.info);
    }
    store.dispatch('common/addAlert', {
      msg: e?.response?.data?.errdesc || `Ошибка ${e.response.status}`,
      type: 'error',
    });
    if (e?.response?.data?.err === 'unauthorized') {
      router.push({ name: 'Login' });
      return null;
    }
  } else if (e.request) {
    store.dispatch('common/addAlert', { msg: 'Не удалось получить ответ от сервера', type: 'error' });
  } else {
    store.dispatch('common/addAlert', { msg: 'Что-то пошло не так', type: 'error' });
  }
  console.error(e);
}

const applyBasicInterceptors = () => {
  api.addRequestHandler(requestHandler);
  api.addErrorHandler(errorHandler);
  api.addResponseHandler(basicResponseHandler);
};

applyBasicInterceptors();

export default store;
