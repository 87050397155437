<template>
  <SidePanel :opened="opened" right :backburgers="backburgers" :current-object="sidePanelType" @toggle="toggle">
    <template v-if="sidePanelType === 'data' && sidePanelDataType === 'node'">
      <v-tabs v-model="tab" grow class="mt-2">
        <v-tab>{{ isRoot ? 'Карта' : 'Степ' }}</v-tab>
        <v-tab v-if="!isRoot && hasTask">Таск</v-tab>
        <v-tab v-if="isRoot">Обработчик ошибок</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-col>
            <ModelForm v-if="isRoot" key="root_model" model-name="flow" :model-id="nodeModuleName + '/root_model'" :disabled="locked" noinit />
            <ModelForm v-else key="step_model" model-name="step" :model-id="nodeModuleName + '/step_model'" :disabled="locked" noinit />
          </v-col>
        </v-tab-item>
        <v-tab-item v-if="!isRoot && hasTask">
          <v-col>
            <v-select v-model="isTaskExe" label="Расширенный тип задания" :items="yesNoOptions" item-text="label" item-value="value" :disabled="locked" />
            <ModelForm v-if="!isTaskExe" key="task_model" model-name="task" :model-id="nodeModuleName + '/task_model'" :disabled="locked" noinit />
            <ModelForm v-else key="task_exe_model" model-name="task_special_exe" :model-id="nodeModuleName + '/task_exe_model'" :disabled="locked" noinit />
          </v-col>
        </v-tab-item>
        <v-tab-item v-if="isRoot">
          <v-col>
            <ModelForm key="error_handler_model" model-name="flow_err" :model-id="nodeModuleName + '/error_handler_model'" :disabled="locked" noinit />
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-else-if="sidePanelType === 'data' && sidePanelDataType === 'edge'">
      <ModelForm key="route_model" class="ma-3" model-name="route" :model-id="edgeModuleName + '/route_model'" :disabled="locked" noinit @field-changed="redrawEdgeOnChange($event)" />
    </template>
    <template v-else-if="sidePanelType === 'process'">
      <div class="scrollable-content">
        <v-card v-for="(log, index) in parsedProcessInfo.logs" :key="index" width="100vw" class="ma-6">
          <v-card-item>
            <v-card-title>{{ log.title }}</v-card-title>
          </v-card-item>
          <v-card-text>{{ log.content }}</v-card-text>
          <v-divider></v-divider>
        </v-card>
        <v-card-actions class="pb-6 justify-space-around">
          <v-btn width="400px">{{ processText }}</v-btn>
        </v-card-actions>
        <v-card-actions class="pb-6 justify-center">
          <v-btn class="primary" :disabled="!canStartProcess" @click="signalProcess">{{ processButtonText }}</v-btn>
          <v-btn class="primary" :disabled="!canStartProcess" @click="refreshProcess">Обновить состояние</v-btn>
        </v-card-actions>
        <v-select v-model="processServer" class="ma-6" label="Целевой сервер" :items="servers" item-text="label" item-value="value" :rules="rules" @click="checkPubs" />
        <v-text-field v-model="processWCF" class="ma-6" label="Наименование сервиса" :rules="rules" />
        <v-text-field v-model="processObject" class="ma-6" label="Объект процесса" :rules="rules" />
        <v-row dense class="ma-6">
          <template v-for="(field, i) in processData">
            <v-col :key="`${field.id}_name`" cols="4">
              <v-text-field v-model="field.name" placeholder="Название поля" dense />
            </v-col>
            <v-col :key="`${field.id}_value`" cols="7">
              <v-text-field v-model="field.value" placeholder="Значение" dense />
            </v-col>
            <v-col :key="`${field.id}_remove`" cols="1">
              <v-btn dark class="red darken-2" @click="removeField(i)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </template>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn rounded class="primary" @click="addEmptyField">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else-if="sidePanelType === 'publication'">
      <v-card-text>
        <ModelForm key="flow_publication" model-name="flow_publication" model-id="flow_publication" />
      </v-card-text>
      <v-card-actions class="pb-6 justify-space-around">
        <v-btn class="primary" @click="publish"> Публикация</v-btn>
      </v-card-actions>
    </template>
  </SidePanel>
</template>

<script>
import SidePanel from '@/components/common/SidePanel';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { SET_NEED_TO_REDRAW, SET_SIDE_PANEL, SET_SIDE_PANEL_TYPE, SET_IS_TASK_EXE } from '@/store/mutations';
import { PUBLISH_FLOW } from '@/store/actions';
import ModelForm from '@/components/modelForm/ModelForm';
import { serverGet, serverPost } from '@/api';
import { required } from '@/utils/rules';
import Guid from '@/utils/Guid';

// TODO: make two components for route/step

export default {
  name: 'EntitySidePanel',
  components: {
    ModelForm,
    SidePanel,
  },
  data() {
    return {
      tab: null,
      backburgers: [
        { text: 'Данные', object: 'data' },
        { text: 'Процесс', object: 'process' },
        { text: 'Публикация', object: 'publication' },
      ],
      yesNoOptions: [
        { value: true, label: 'Да' },
        { value: false, label: 'Нет' },
      ],
      servers: [],
      processState: 'empty',
      processObject: '',
      processData: [],
      processServer: '',
      processWCF: 'WCF',
    };
  },
  computed: {
    ...mapGetters('flows', ['moduleName', 'nodeModuleName', 'edgeModuleName']),
    ...mapState('flows', ['sidePanelType', 'sidePanelDataType', 'sidePanelOpened', 'activeFlowId', 'activeNodeId', 'activeEdgeId']),
    ...mapState('common', ['processInfo']),
    parsedProcessInfo: {
      get() {
        return this.processInfo || { logs: [] };
      },
    },
    processText() {
      if (this.processState === 'empty') {
        return 'Процесс не запущен';
      } else if (this.processState === 'executing') {
        return 'Выполнение...';
      } else if (this.processState === 'wait') {
        return 'Ожидание действия';
      } else if (this.processState === 'finished') {
        return 'Процесс завершён успешно';
      } else if (this.processState === 'error') {
        return 'Ошибка выполнения процесса';
      }

      return 'Ошибка!';
    },
    processButtonText() {
      if (this.processState === 'empty') {
        return 'Запуск процесса';
      } else if (this.processState === 'executing') {
        return 'Перезапуск процесса';
      } else if (this.processState === 'wait') {
        return 'Отправить сигнал';
      } else if (this.processState === 'finished') {
        return 'Запуск процесса';
      } else if (this.processState === 'error') {
        return 'Перезапуск процесса';
      }

      return 'Ошибка!';
    },
    rules() {
      return [required('Обязательное поле')];
    },
    opened: {
      get() {
        return this.sidePanelOpened;
      },
      set(newVal) {
        this[SET_SIDE_PANEL](newVal);
      },
    },
    isTaskExe: {
      get() {
        return this.$store.state[this.nodeModuleName]?.is_task_exe;
      },
      set(newVal) {
        this.$store.commit(`${this.nodeModuleName}/${SET_IS_TASK_EXE}`, newVal);
      },
    },
    sidePanelDescription() {
      return this.$store.state[this.nodeModuleName]?.description || null;
    },
    hasTask() {
      return !!this.$store.state[this.nodeModuleName]?.has_task;
    },
    data() {
      return this.$store.state[this.nodeModuleName]?.data || {};
    },
    task_data() {
      return this.$store.state[this.nodeModuleName]?.task_data || {};
    },
    route_data() {
      return this.$store.state[this.edgeModuleName]?.data || {};
    },
    locked() {
      return this.$store.state[this.moduleName]?.locked;
    },
    isRoot() {
      return this.$store.state[this.nodeModuleName]?.type === 'root';
    },
    publcationData() {
      return this.$store.getters['modelForm_flow_publication/data'];
    },
    canStartProcess() {
      return !!(this.processServer && this.processWCF && this.processObject);
    },
  },
  methods: {
    ...mapMutations('flows', [SET_NEED_TO_REDRAW, SET_SIDE_PANEL, SET_SIDE_PANEL_TYPE]),
    toggle(burger) {
      if (this.opened) {
        if (this.sidePanelType === burger?.object) {
          this.opened = false;
        }
      } else {
        this.opened = true;
      }

      this[SET_SIDE_PANEL_TYPE](burger?.object);
    },
    redrawEdgeOnChange(ev) {
      if (ev.fieldName === 'type') {
        this[SET_NEED_TO_REDRAW](true);
      }
    },
    addEmptyField() {
      this.processData.push({ id: Guid.newid, name: '', value: '' });
    },
    removeField(index) {
      this.processData.splice(index, 1);
    },
    async checkPubs() {
      const processPubs = await serverGet('/help/get_publication_sources');
      this.servers = processPubs;
    },
    async publish() {
      await this.$store.dispatch(`${this.moduleName}/${PUBLISH_FLOW}`);
    },
    async signalProcess() {
      const paramsParsed = {};
      this.processData.forEach((d) => {
        if (d.name) {
          const keys = d.name.split('.').filter((k) => k !== '');
          if (keys.length > 1) {
            keys.reduce((obj, key, idx) => {
              if (idx === keys.length - 1) {
                return (obj[key] = d.value);
              } else {
                return typeof obj[key] === 'object' && obj[key] != null ? obj[key] : (obj[key] = {});
              }
            }, paramsParsed);
          } else {
            paramsParsed[d.name] = d.value;
          }
        }
      });

      // Запуск нового процесса по карте
      if (this.processState === 'empty' || this.processState === 'finished' || this.processState === 'error' || this.processState === 'executing') {
        await serverPost('/map/test', {
          flowid: this.activeFlowId,
          data_source: this.processServer,
          target_WCF: this.processWCF,
          params: { signal: 'start_process', process_object: this.processObject, params: paramsParsed },
        });
      }

      // Если карта наткнулась на состояние ожидания, то процесс необходимо продолжить вручную
      if (this.processState === 'wait') {
        let processStatus = await serverPost('/help/get_process_status', { flowid: this.activeFlowId, data_source: this.processServer, service: this.processWCF });

        await serverPost('/map/test', {
          flowid: this.activeFlowId,
          data_source: this.processServer,
          target_WCF: this.processWCF,
          params: {
            signal: 'manual_continue',
            processid: processStatus.processid,
            process_object: this.processObject,
            params: paramsParsed,
          },
        });
      }

      await this.refreshProcess();
    },
    async refreshProcess() {
      let processStatus = await serverPost('/help/get_process_status', { flowid: this.activeFlowId, data_source: this.processServer, service: this.processWCF });

      if (processStatus.status === 'manual_wait') {
        this.processState = 'wait';
      } else if (processStatus.status === 'complete') {
        this.$store.commit('common/setProcessInfo', null);
        this.processState = 'finished';
        return;
      } else if (processStatus.status === 'error') {
        this.processState = 'error';
      } else {
        this.processState = 'executing';
      }

      let data = await serverPost('/help/get_process_info', { flowid: this.activeFlowId, data_source: this.processServer, service: this.processWCF });
      this.$store.commit('common/setProcessInfo', data);
    },
  },
};
</script>

<style scoped lang="scss">
.scrollable-content {
  height: 100%;
  padding: 20px 0 50px 0;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
