<template>
  <v-main>
    <v-container class="mt-16"> </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'DevView',
  data() {
    return {};
  },
  computed: {},
};
</script>
