import { SET_MODEL_TYPES, SET_STEP_SETTINGS } from '@/store/mutations';
import { GET_MODEL_TYPES, GET_STEP_SETTINGS } from '@/store/actions';
import { getSBModelTypes, serverGet } from '@/api';

const initState = () => ({
  modelTypes: [],
  stepSettings: [],
  activeEntity: undefined, // "model#123333" | "step#12454545" | "prototype#3556659"
});

export const stepBuilder = {
  namespaced: true,
  state: initState(),
  getters: {
    modelTypesLoaded: (state) => !!state.modelTypes?.length,
    stepSettingsLoaded: (state) => !!state.stepSettings?.length,
    activeEntityID: (state) => state.activeEntity?.split('#')[1] || null,
  },
  mutations: {
    [SET_MODEL_TYPES]: (state, payload) => {
      state.modelTypes = payload;
    },
    [SET_STEP_SETTINGS]: (state, payload) => {
      state.stepSettings = payload;
    },
  },
  actions: {
    [GET_MODEL_TYPES]: async ({ commit }) => {
      const types = await getSBModelTypes();
      commit(SET_MODEL_TYPES, types);
    },
    [GET_STEP_SETTINGS]: async ({ commit }) => {
      const fields = [];
      fields.forEach(async (field) => {
        field.value = undefined;
        if (field.values_method) field.options = await serverGet(field.values_method);
      });
      commit(SET_STEP_SETTINGS, fields);
    },
  },
};
