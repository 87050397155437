import Vue from 'vue';
import App from './App.vue';
import store from './store';
import vuetify from './plugins/vuetify';
import mdi from '../node_modules/@mdi/font/css/materialdesignicons.css';
import router from './router';
import { createHttp } from '@/api/http';

Vue.config.productionTip = false;

createHttp().then(() => {
  new Vue({
    store,
    vuetify,
    render: (h) => h(App),
    router,
    mdi,
  }).$mount('#app');
});
