<template>
  <v-list-item-group class="alert">
    <v-alert v-for="alert in alerts" :key="alert.id" :type="alert.type || 'error'">
      {{ alert.msg }}
    </v-alert>
  </v-list-item-group>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Alert',

  computed: {
    ...mapGetters({
      alerts: 'common/alerts',
    }),
  },
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
}
</style>
