<template>
  <v-container class="pa-0 ma-0">
    <v-menu v-if="hasTooltip" open-on-hover offset-y :close-on-content-click="false" top nudge-top="10" content-class="elevation-1" max-width="300px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon small v-bind="attrs" class="mt-n4 ml-n4 float-left" v-on="on">mdi-information</v-icon>
      </template>

      <v-card flat height="100%" width="100%" class="ma-0 pa-0">
        <v-card-text>
          {{ tooltipText }}
        </v-card-text>
      </v-card>
    </v-menu>
    <v-card
      :id="id"
      max-width="200"
      flat
      :class="{
        'entity-error': hasError,
        'entity-wait': hasWait,
        'entity-success': hasSuccess,
        'entity-active': isActive,
      }"
      @dblclick="openSidePanel"
      @mouseup.native.capture="(e) => setLastMove(e)"
    >
      <div class="header d-flex justify-space-between align-center pl-4 pr-8 py-3">
        <div v-if="root" class="root d-flex justify-center align-center pl-0 mr-1">
          <v-icon color="white" small>mdi-home</v-icon>
        </div>
        <div v-if="step" class="step d-flex justify-center align-center pl-0 mr-2">
          <v-icon color="white" small>mdi-flag-variant</v-icon>
        </div>
        <v-card-text class="text-subtitle-2">{{ nodeName }}</v-card-text>
      </div>
      <v-card-title class="pb-1 text-body-2">{{ nodeType }}</v-card-title>
      <v-card-text>{{ nodeDescription }}</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { SET_ACTIVE_NODE_ID, SET_SIDE_PANEL, SET_SIDE_PANEL_TYPE, SET_SIDE_PANEL_DATA_TYPE, SET_LAST_NODE_MOVE } from '@/store/mutations';
import { mapMutations, mapGetters, mapState } from 'vuex';
import { INIT_NODE } from '@/store/actions';

export default {
  name: 'FlowNode',
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
    root: {
      type: Boolean,
      default: false,
      required: false,
    },
    step: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters('flows', ['moduleName', 'nodeModuleName']),
    ...mapState('flows', ['activeNodeId']),
    ...mapState('common', ['temporaryErrors', 'errNodes', 'processInfo']),
    hasError() {
      return (
        this.errNodes[this.idUp] ||
        this.temporaryErrors?.findIndex(({ elementid }) => this.id === elementid) !== -1 ||
        (this.processInfo?.logs && this.processInfo?.logs?.findIndex(({ nodeid, status }) => this.id === nodeid && status === 'err') !== -1) | false
      );
    },
    hasWait() {
      return this.processInfo?.logs && this.processInfo?.logs?.findIndex(({ nodeid, status }) => this.id === nodeid && status === 'wait') !== -1;
    },
    hasSuccess() {
      return this.processInfo?.logs && this.processInfo?.logs?.findIndex(({ nodeid, status }) => this.id === nodeid && status === 'ok') !== -1;
    },
    hasTooltip() {
      console.log('ToolTIPO', this.errNodes, this.id);
      return this.errNodes[this.idUp] != undefined;
    },
    tooltipText() {
      return this.errNodes[this.idUp];
    },
    idUp() {
      return this.id.toLowerCase();
    },
    isActive() {
      return this.id === this.activeNodeId;
    },
    currentModuleName() {
      return `${this.moduleName}/node_${this.id}`;
    },
    currentModuleState() {
      return this.$store.state[this.currentModuleName];
    },
    currentModuleData() {
      return !this.root ? this.$store.getters[`modelForm_${this.currentModuleName}/step_model/data`] : this.$store.getters[`modelForm_${this.currentModuleName}/root_model/data`];
    },
    nodeName() {
      return this.currentModuleData?.name || this.currentModuleState?.data?.name || this.name || 'Новый степ';
    },
    nodeDescription() {
      return this.currentModuleData?.description || this.currentModuleState?.data?.description || this.description || this.null;
    },
    nodeType() {
      return !this.root ? this.currentModuleData?.type || this.currentModuleState?.data?.type || '*НЕИЗВЕСТНЫЙ ТИП ШАГА*' : this.null;
    },
  },
  methods: {
    ...mapMutations('flows', [SET_SIDE_PANEL, SET_ACTIVE_NODE_ID, SET_SIDE_PANEL_TYPE, SET_SIDE_PANEL_DATA_TYPE]),
    async openSidePanel() {
      // if (!this.$store.state[this.moduleName].locked) {
      this[SET_ACTIVE_NODE_ID](this.id);
      await this.$store.dispatch(`${this.nodeModuleName}/${INIT_NODE}`);
      this[SET_SIDE_PANEL_TYPE]('data');
      this[SET_SIDE_PANEL_DATA_TYPE]('node');
      this[SET_SIDE_PANEL](true);
      // }
    },
    setLastMove(e) {
      const x = e.clientX - 100;
      const y = e.clientY - 100;
      this.$store.commit(`${this.moduleName}/${SET_LAST_NODE_MOVE}`, { id: this.id, x, y });
    },
  },
};
</script>

<style scoped lang="scss">
%node-icon {
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
}
.root {
  @extend %node-icon;
  background-color: #1e2222;
}
.step {
  @extend %node-icon;
  background-color: #2222d2;
}
.header {
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
}
.body {
  min-height: 50px;
}
.options {
  font-size: 14px;
  color: #1e2222;
  &__span {
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
  }
}

.entity-error {
  border: 3px solid red;
}
.entity-wait {
  border: 3px solid rgb(49, 87, 255);
}
.entity-success {
  border: 3px solid green;
}
.entity-active {
  box-shadow: 0 0 3px 2px rgba(128, 0, 128, 0.5) !important;
}
</style>
