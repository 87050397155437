<template>
  <div v-if="activeFlowId" class="map-controls">
    <v-btn outlined class="map-controls__button" @click="toggleLock">
      <v-icon v-if="locked">mdi-lock-outline</v-icon>
      <v-icon v-else>mdi-lock-open-variant-outline</v-icon>
    </v-btn>
    <v-btn outlined class="map-controls__button" @click="zoomIn">
      <v-icon>mdi-magnify-plus-outline</v-icon>
    </v-btn>
    <v-btn outlined class="map-controls__button" @click="zoomReset">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn outlined class="map-controls__button" @click="zoomOut">
      <v-icon>mdi-magnify-minus-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { TOGGLE_LOCK } from '@/store/mutations';

export default {
  name: 'MapControls',
  computed: {
    ...mapState('flows', ['activeFlowId']),
    ...mapGetters('flows', ['moduleName']),
    locked: {
      get() {
        return this.$store.state[this.moduleName].locked;
      },
      set(newVal) {
        this.$store.dispatch(`${this.moduleName}/${TOGGLE_LOCK}`, newVal);
      },
    },
  },
  methods: {
    toggleLock() {
      this.$emit('lock-toggle');
    },
    zoomIn() {
      this.$emit('zoom-in');
    },
    zoomOut() {
      this.$emit('zoom-out');
    },
    zoomReset() {
      this.$emit('zoom-reset');
    },
  },
};
</script>

<style scoped lang="scss">
.map-controls {
  width: 100%;
  justify-content: center;
  display: flex;
  &__button {
    width: 36px;
    min-width: unset;
  }
  &__button + &__button {
    margin-left: 15px;
  }
}
</style>
