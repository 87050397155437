/** @type {{ BASE_API_URL: string, BASE_API_SERVICE: string }}*/
let settings;

if (process.env.NODE_ENV === 'production') {
  settings = require('../appsettings.prod.json');
} else {
  settings = require('../appsettings.dev.json');
}

const APP_SETTINGS = settings;
export default APP_SETTINGS;
