<template>
  <v-app-bar color="grey lighten-4" dense height="64" elevation="1" clipped-right clipped-left app>
    <v-container fluid>
      <v-row class="justify-end">
        <v-menu bottom min-width="300px" max-width="500px" open-on-hover rounded offset-y :close-delay="300">
          <template v-slot:activator="{ on }">
            <v-btn icon plain :ripple="false" x-large v-on="on">
              <v-icon>{{ !userIsAdmin ? 'mdi-account' : 'mdi-crown' }}</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <span class="black--text text-h6">{{ userName }}</span>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="logout">Выйти</v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import server from '@/api/server';
import { mapGetters } from 'vuex';
export default {
  name: 'AppBar',
  computed: {
    ...mapGetters('common', ['userName', 'userIsAdmin']),
  },
  async created() {
    await this.$store.dispatch('common/updateUserInfo');
    const currentRoute = this.$route.name;
    if (!currentRoute) {
      this.$router.push('/');
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('tnx');
      server.defaults.params.tnx = null;
      return this.$router.push({ name: 'Login' });
    },
  },
};
</script>

<style scoped lang="scss"></style>
