<template>
  <v-card>
    <v-card-title>
      {{ cardTitle }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="4">
            <v-text-field v-model="prototypeName" label="Название прототипа" placeholder="Название прототипа" :rules="[rules.required]" dense />
          </v-col>
          <v-col cols="4">
            <v-select v-model="inputModelID" :items="modelOptions || []" item-text="name" item-value="id" label="Входная модель" :rules="[rules.required]" dense />
          </v-col>
          <v-col cols="4">
            <v-select v-model="outputModelID" :items="modelOptions || []" item-text="name" item-value="id" label="Выходная модель" :rules="[rules.required]" dense />
          </v-col>
          <template v-if="inputModelID && outputModelID">
            <v-col cols="12" class="text-h6"> Предзаполненные поля</v-col>
            <v-col cols="6">
              <v-row v-if="inputModelFields" dense>
                <v-col cols="12" class="text-center"> Вход</v-col>
                <v-col v-for="field in inputModelFields" :key="field.field" cols="12">
                  <!-- TODO: Different inputs for different types -->
                  <v-tooltip top :disabled="!field.description">
                    <template #activator="{ on, attrs }">
                      <v-select
                        v-if="field.type === 'boolean'"
                        v-model="field.value"
                        :items="[true, false]"
                        :rules="getFieldRules(field.type, field.length)"
                        :label="field.field"
                        :placeholder="getFieldPlaceholder(field.type)"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                      <v-text-field
                        v-else-if="field.type === 'datetime'"
                        v-model="field.value"
                        v-mask="'####-##-##T##:##:##'"
                        :rules="getFieldRules(field.type, field.length)"
                        :label="field.field"
                        :placeholder="getFieldPlaceholder(field.type)"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                      <v-text-field
                        v-else
                        v-model="field.value"
                        :rules="getFieldRules(field.type, field.length)"
                        :label="field.field"
                        :placeholder="getFieldPlaceholder(field.type)"
                        :type="field.type"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                    </template>
                    {{ field.description }}
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row v-if="outputModelFields" dense>
                <v-col cols="12" class="text-center"> Выход</v-col>
                <v-col v-for="field in outputModelFields" :key="field.field" cols="12">
                  <!-- TODO: Different inputs for different types -->
                  <v-tooltip top :disabled="!field.description">
                    <template #activator="{ on, attrs }">
                      <v-select
                        v-if="field.type === 'boolean'"
                        v-model="field.value"
                        :items="[true, false]"
                        :rules="getFieldRules(field.type, field.length)"
                        :label="field.field"
                        :placeholder="getFieldPlaceholder(field.type)"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                      <v-text-field
                        v-else-if="field.type === 'datetime'"
                        v-model="field.value"
                        v-mask="'####-##-##T##:##:##'"
                        :rules="getFieldRules(field.type, field.length)"
                        :label="field.field"
                        :placeholder="getFieldPlaceholder(field.type)"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                      <v-text-field
                        v-else
                        v-model="field.value"
                        :rules="getFieldRules(field.type, field.length)"
                        :label="field.field"
                        :placeholder="getFieldPlaceholder(field.type)"
                        :type="field.type"
                        v-bind="attrs"
                        dense
                        v-on="on"
                      />
                    </template>
                    {{ field.description }}
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="error" @click="deleteEntity">Удалить</v-btn>
      <v-btn class="primary" @click="submitEntity">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { buildSBPrototype, deleteSBEntity, getSBEntity, getSBSchemas } from '@/api';
import { ENTITY_MAP, SBDialogTitles } from '@/utils/constants';
import { max, required } from '@/utils/rules';
import { SBPrototypeFactory } from '@/utils/SBPrototypeFactory';
import { mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';

export default {
  name: 'PrototypeForm',
  directives: { mask },
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    modelOptions: [],
    prototypeName: '',
    inputModelID: undefined,
    outputModelID: undefined,
    oldEntityID: undefined,
    rules: {
      required: required('Обязательное поле'),
    },
  }),
  computed: {
    activeEntityId() {
      return this.$route.params.entityId;
    },
    cardTitle() {
      return this.prototypeName || SBDialogTitles.prototypes;
    },
    inputModelFields() {
      return this.modelOptions?.find((model) => model.id === this.inputModelID)?.schema?.map((field) => ({ ...field, value: undefined })) || [];
    },
    outputModelFields() {
      return this.modelOptions?.find((model) => model.id === this.outputModelID)?.schema?.map((field) => ({ ...field, value: undefined })) || [];
    },
    isNew() {
      return this.activeEntityId === 'new';
    },
  },
  watch: {
    activeEntityId: {
      async handler(newVal) {
        if (!newVal || newVal === this.oldEntityID) {
          return;
        }

        this.oldEntityID = newVal;
        this.modelOptions = await getSBSchemas();
        await this.initEntity(newVal);

        this.setTabName({
          fullPath: this.$route.fullPath,
          newName: `Прототип - ${this.cardTitle || 'Безымянный'}`,
        });
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('tabs', ['setTabName', 'removeTab']),

    getFieldRules(type, maxLength) {
      // FIXME: Doesn't check for anything except string
      if (maxLength !== 0 && !maxLength) return [];
      return [max(maxLength)];
    },

    getFieldPlaceholder(type) {
      // TODO: maybe from backend?
      return type === 'datetime' ? new Date().toISOString() : `Значение по умолчанию`;
    },

    async submitEntity() {
      if (this.$refs.form.validate()) {
        const payload = SBPrototypeFactory({
          name: this.prototypeName,
          inputId: this.inputModelID,
          outputId: this.outputModelID,
          inputFields: this.inputModelFields,
          outputFields: this.outputModelFields,
          updating: !this.isNew,
        });
        const result = await buildSBPrototype(payload);

        // Change current route with new id
        if (this.isNew) {
          this.removeTab({ fullPath: this.$route.fullPath });
          this.$router.push(`/${ENTITY_MAP.sb_prototypes.baseRoute}/${result.id}`);
        }
      }
    },
    async deleteEntity() {
      await deleteSBEntity({ type: 'prototype', id: this.activeEntityId });
      this.removeTab({ fullPath: this.$route.fullPath });
    },
    saveDate(field, isInput = true) {
      this.$refs[`${isInput ? 'input' : 'output'}_date_${field}`][0].save(isInput ? this.inputDates[field] : this.outputDates[field]);
      isInput ? (this.openInputDatePickers[field] = false) : (this.openOutputDatePickers[field] = false);
      this.saveDateTimeField(field, isInput);
    },
    saveTime(field, isInput = true) {
      this.$refs[`${isInput ? 'input' : 'output'}_time_${field}`][0].save(isInput ? this.inputTimes[field] : this.outputTimes[field]);
      isInput ? (this.openInputTimePickers[field] = false) : (this.openOutputTimePickers[field] = false);
      this.saveDateTimeField(field, isInput);
    },
    saveDateTimeField(field, isInput = true) {
      const fieldIndex = isInput ? this.inputModelFields.findIndex((_field) => _field.field === field) : this.outputModelFields.findIndex((_field) => _field.field === field);
      if (fieldIndex !== -1) {
        isInput
          ? (this.inputModelFields[fieldIndex].value = `${this.inputDates[field]}T${this.inputTimes[field]}:00`)
          : (this.outputModelFields[fieldIndex].value = `${this.outputDates[field]}T${this.outputTimes[field]}:00`);
      }
    },
    async initEntity(id) {
      if (id && id !== 'new') {
        const prototype = await getSBEntity({ type: 'prototype', id });
        this.prototypeName = prototype.name;
        this.inputModelID = prototype.input_schema;
        this.outputModelID = prototype.output_schema;
        const predefined = prototype.predefined_data;
        if (predefined) {
          const { input, output } = predefined;
          if (input) {
            Object.keys(input).forEach((field) => {
              const predefinedField = this.inputModelFields.find((_field) => _field.field === field);
              if (predefinedField) {
                predefinedField.value = input[field];
              }
            });
          }
          if (output) {
            Object.keys(output).forEach((field) => {
              const predefinedField = this.outputModelFields.find((_field) => _field.field === field);
              if (predefinedField) {
                predefinedField.value = output[field];
              }
            });
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
