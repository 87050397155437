<template>
  <div class="flows">
    <AsideBrowser />
    <MainMap />
    <EntitySidePanel />
    <RefreshFlowDialog />
  </div>
</template>

<script>
import MainMap from '@/components/MainMap';
import AsideBrowser from '@/components/AsideBrowser';
import EntitySidePanel from '@/components/flows/EntitySidePanel';
import RefreshFlowDialog from '@/components/flows/RefreshFlowDialog';

export default {
  name: 'FlowsView',
  components: {
    EntitySidePanel,
    MainMap,
    AsideBrowser,
    RefreshFlowDialog,
  },
};
</script>

<style scoped lang="scss">
.flows {
  height: $content-height;
  overflow: hidden;

  &__main {
    max-height: $content-height; //header height;
    margin-top: $content-margin-top;
  }
}
</style>
